import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";
import { NgxPaginationModule } from "ngx-pagination";

@Component({
  selector: "app-vendor-profile",
  templateUrl: "./vendor-profile.component.html",
  styleUrls: ["./vendor-profile.component.css"]
})
export class VendorProfileComponent implements OnInit {
  public transactionForm: FormGroup;
  public filterOrderForm: FormGroup;
  public filterTransForm: FormGroup;
  pageHeading = "";
  user = {};
  userChangeStatus = {};
  vendor: any = [];
  wallet: any = [];
  failMessage = "";
  successMessage = "";
  vendor_info: any = [];
  collection: any = [];
  vendor_transactions = [];
  suspendUser = false;
  suspendReason = "";
  showModal = true;
  vendor_user_id = 0;
  vendor_order_data = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public api: ApiService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Form Model Declaration with form validation for the fields
    this.filterOrderForm = this.fb.group({
      keyword: [""]
    });

    this.filterTransForm = this.fb.group({
      keyword: [""]
    });

    this.transactionForm = this.fb.group({
      user_id: [this.myapp.loginUserkiId],
      token: [this.myapp.token],
      user_type: ["vendor"],
      paid_to_id: ["", [Validators.required]],
      transaction_id: [
        "",
        [Validators.required, Validators.minLength(2), Validators.maxLength(50)]
      ],
      amount: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      date: ["", [Validators.required]],
      description: [""]
    });

    // Pagination test
    for (let i = 1; i <= 100; i++) {
      let Obj = { name: `Employee Name ${i}`, code: `EMP00 ${i}` };
      this.collection.push(Obj);
    }

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadUserProfile(id);
        this.vendor_user_id = id;
        this.pageHeading = "Vendor Profile";
      }
    });
  }

  loadUserProfile(id) {
    this.user = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      vendor_user_id: id
    };
    this.spinner.show();
    this.api
      .callAPI(this.user, "admin/getVendorProfile")
      .subscribe(response => {
        this.spinner.hide();
        if (response.status === true) {
          this.spinner.hide();
          this.vendor = response.data;
          this.wallet = response.data.wallet_amount;
          this.vendor_info = response.data.vendor_data;
          this.vendor_order_data = response.data.vendor_order_data;
          this.vendor_transactions = response.data.vendor_transactions;
          console.log(this.vendor_info);
          //console.log("orders", this.vendor["vendor_order_data"]);
          this.successMessage = response.message;

          //Patch the Driver ID
          this.transactionForm.patchValue({
            paid_to_id: this.vendor_info.vendor_id
          });
        } else {
          this.spinner.hide();
          this.failMessage = response.message;
          console.log(response.message);
        }
      });
  }

  showSuspendBox() {
    this.suspendUser = true;
  }

  cancelSuspend() {
    this.suspendUser = false;
    this.suspendReason = "";
  }

  changeStatus(id, value) {
    this.userChangeStatus = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      status: value,
      vendor_user_id: id,
      reason: this.suspendReason
    };
    console.log(this.userChangeStatus);
    this.spinner.show();
    this.api
      .callAPI(this.userChangeStatus, "admin/vendorChangeStatus")
      .subscribe(response => {
        this.spinner.hide();
        if (response.status === true) {
          this.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.vendor_info["status"] = value;
          this.suspendUser = false;
          if (value == 2)
            this.vendor_info["suspend_reason"] = this.suspendReason;
          else {
            this.vendor_info["suspend_reason"] = null;
            this.suspendReason = "";
          }
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
  }

  //trasaction form submit function
  onTansSubmit() {
    let canSubmit = true;
    let amount = this.amount.value;
    if (amount <= 0) {
      canSubmit = false;
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please enter a valid amount";
      return false;
    }

    if (canSubmit) {
      this.myapp.spinner.show();
      //console.log("Service Image:" + this.service_image.value);
      this.api
        .callAPI(this.transactionForm.value, "admin/makePayment")
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.resetForm();

            //Deduct the wallet balance
            //this.vendor_info.walletAmt =
            //this.vendor_info.walletAmt - this.amount.value;

            // Hide the Modal
            this.showModal = false;
            let closeModal: HTMLElement = document.getElementsByClassName(
              "body"
            )[0] as HTMLElement;
            closeModal.classList.remove("modal-open");
            let closeModalpadding: HTMLElement = document.getElementsByClassName(
              "body"
            )[0] as HTMLElement;
            closeModalpadding.style.padding = "0px";
            let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
              "modal-backdrop"
            )[0] as HTMLElement;
            ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);

            this.ngOnInit();
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.transactionForm.reset();
    //Patch the required data in Form Modal
    this.transactionForm.patchValue({
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      user_type: "vendor",
      paid_to_id: this.vendor_info.vendor_id,
      transaction_id: "",
      amount: "",
      date: "",
      description: ""
    });
  }

  //Getor - To access the fields directly in the form in HTML
  get transaction_id() {
    return this.transactionForm.get("transaction_id");
  }
  get amount() {
    return this.transactionForm.get("amount");
  }
  get date() {
    return this.transactionForm.get("date");
  }
  get description() {
    return this.transactionForm.get("description");
  }

  //On Keyword filter
  filterOrderData(vendorId) {
    this.myapp.showErrorAlert = false;
    this.myapp.errorMsg = "";
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", this.filterOrderForm.get("keyword").value);
    formData.append("vendor_user_id", vendorId);

    this.api
      .callAPI(formData, "admin/getFilterVendorOrders")
      .subscribe(response => {
        if (response.status === true) {
          this.vendor_order_data = response.data.vendor_order_data;
          //console.log(response.data);
        } else {
          this.vendor_order_data = [];
        }
      });
  }

  //Transactions filter
  filterTransData(vendorId) {
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", this.filterTransForm.get("keyword").value);
    formData.append("vendor_user_id", vendorId);

    this.api
      .callAPI(formData, "admin/getFilterVendorPayments")
      .subscribe(response => {
        if (response.status === true) {
          this.vendor_transactions = response.data;
          //console.log(response.data);
        } else {
          this.vendor_transactions = [];
        }
      });
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.api.convertToDateTime(data, option);
  }
}
