import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";
import { ApiService } from "src/app/service/api.service";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-vendor-settings",
  templateUrl: "./vendor-settings.component.html",
  styleUrls: ["./vendor-settings.component.css"]
})
export class VendorSettingsComponent implements OnInit {
  profileForm: FormGroup;
  public orders: any = [];
  profile: any = [];
  imgPreviewLogoPath = null;
  imgPreviewBannerPath = null;
  FileErrorLogoMessageLog = false;
  FileErrorBannerMessageLog = false;
  successMessage = "";
  failMessage = "";
  pageHeading = "";
  public ordersCount: any = [];
  public profilePath = null;
  page = 1; //used for load more
  hideLoadMore = true;
  order = {};
  imageChangedEvent: any = "";
  imagebannerHide = false;
  imagebannerSave = false;
  chooseFileLabel = "Choose a file...";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public myapp: AppComponent,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.getMyProfile();
    this.pageHeading = "Settings";
    //Form Model Declaration with form validation for the fields
    this.profileForm = this.fb.group({
      business_logo: [""],
      business_banner: [""]
    });
  }

  getMyProfile() {
    this.api
      .callAPI(this.myapp.loginVendorUser, "vendor/getProfile")
      .subscribe(response => {
        if (response.status === true) {
          this.profile = response.data;
          if (response.data.business_logo) {
            this.imgPreviewLogoPath = response.data.business_logo;
          }

          if (response.data.business_banner) {
            this.imgPreviewBannerPath = response.data.business_banner;
          }
          //console.log(this.profile);
        } else {
          this.profile = [];
        }
      });
  }

  //on File select, put the selected file in Form model variable
  onFileSelect(event: any, type: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      //console.log("files" + file);
      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      if (fileType === "image") {
        if (type == "logo") {
          this.profileForm.get("business_logo").setValue(file);
          this.FileErrorLogoMessageLog = false;
          //Preveiw of the image only on images
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = _event => {
            this.imgPreviewLogoPath = reader.result;
          };
        }
        if (type == "banner") {
          this.imagebannerHide = true;
          this.imagebannerSave = true;
          //this.serviceForm.get("service_image").setValue(file);
          this.profileForm.get("business_banner").setValue(file);
          this.imageChangedEvent = event;
          this.FileErrorBannerMessageLog = false;
          //Preveiw of the image only on images
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = _event => {
            this.imgPreviewBannerPath = reader.result;
          };
        }
        return;
      } else {
        if (type == "logo") {
          this.FileErrorLogoMessageLog = true;
        } else if (type == "banner") {
          this.FileErrorBannerMessageLog = true;
        }
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log("Cropping: " + JSON.stringify(event));
    this.imgPreviewBannerPath = event.base64;
    this.profileForm.get("business_banner").setValue(event.base64);
  }

  saveCropImg() {
    this.imagebannerSave = false;
  }

  //form submit function
  onFormSubmit() {
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append(
      "business_logo",
      this.profileForm.get("business_logo").value
    );
    formData.append(
      "business_banner",
      this.profileForm.get("business_banner").value
    );
    formData.append("vendor_id", this.myapp.vendorUserId);
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);

    this.api.callAPI(formData, "vendor/updateImages").subscribe(response => {
      if (response.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
      } else {
        this.myapp.spinner.hide();
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  changePhone() {
    let conf = confirm("Do you want to change your phone number?");
    if (conf) {
      this.myapp.spinner.show();
      this.api
        .callAPI(this.myapp.loginUser, "vendor/changeMyPhone1")
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            localStorage.removeItem("value");
            localStorage.removeItem("otp");
            localStorage.removeItem("mobile_number");
            window.localStorage.setItem("value", "1");
            window.localStorage.setItem("otp", response.data.otp);
            window.localStorage.setItem(
              "mobile_number",
              response.data.mobile_number
            );
            this.router.navigate([
              "../vendor/verify-phone-otp/" + response.data.user_verification_id
            ]);
            console.log(response.data);
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }

  changeEmail() {
    let conf = confirm("Do you want to change your email?");
    if (conf) {
      this.myapp.spinner.show();
      this.api
        .callAPI(this.myapp.loginUser, "vendor/requestChangeMyEmail1")
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            localStorage.removeItem("value");
            localStorage.removeItem("otp");
            window.localStorage.setItem("value", "1");
            window.localStorage.setItem("otp", response.data.otp);

            this.router.navigate([
              "../vendor/verify-email-otp/" + response.data.user_verification_id
            ]);
            console.log(response.data);
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }
}
