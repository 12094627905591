import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-app-setting",
  templateUrl: "./app-setting.component.html",
  styleUrls: ["./app-setting.component.css"]
})
export class AppSettingComponent implements OnInit {
  public pageHeading = "Settings";
  public settingsForm: FormGroup;
  public customerForm: FormGroup;
  public vendorForm: FormGroup;
  public driverForm: FormGroup;
  public websiteForm: FormGroup;
  settings: any = [];
  user = {};

  editorConfig = {
    editable: true,
    spellcheck: false,
    height: "10rem",
    minHeight: "5rem",
    placeholder: "Type something. Test the Editor... ヽ(^。^)丿",
    translate: "no"
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}
  ngOnInit() {
    this.loadSettings();

    this.myapp.successMsg = "";
    this.myapp.errorMsg = "";
    /*this.myapp.spinner.show();
    setTimeout(() => {
      this.myapp.spinner.hide();
    }, 2000);*/

    //Form Model Declaration with form validation for the fields
    this.settingsForm = this.fb.group({
      setting_type: [""],
      tax_rate: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      contact_phone: [
        "",
        [Validators.required, Validators.minLength(8), Validators.maxLength(15)]
      ],
      contact_email: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
          Validators.email
        ]
      ],
      amount_from_vendor: [
        "0"
        /*[
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]*/
      ],
      amount_to_driver: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ]
    });

    this.customerForm = this.fb.group({
      setting_type: [""],
      customer_terms_conditions: [""],
      customer_privacy_policy: [""],
      customer_about_us: [""]
    });
    this.vendorForm = this.fb.group({
      setting_type: "",
      vendor_terms_conditions: [""],
      vendor_privacy_policy: [""]
    });
    this.driverForm = this.fb.group({
      setting_type: "",
      driver_terms_conditions: [""],
      driver_privacy_policy: [""]
    });
  }

  loadSettings() {
    this.api
      .callAPI(this.myapp.loginUser, "admin/getSettings")
      .subscribe(response => {
        if (response.status === true) {
          this.settingsForm.patchValue({
            tax_rate: response.data.settings.tax_rate,
            contact_phone: response.data.settings.contact_phone,
            contact_email: response.data.settings.contact_email,
            amount_to_driver: response.data.settings.amount_to_driver,
            amount_from_vendor: response.data.settings.amount_from_vendor
          });
          this.driverForm.patchValue({
            driver_terms_conditions:
              response.data.settings.driver_terms_conditions,
            driver_privacy_policy: response.data.settings.driver_privacy_policy
          });
          this.vendorForm.patchValue({
            vendor_terms_conditions:
              response.data.settings.vendor_terms_conditions,
            vendor_privacy_policy: response.data.settings.vendor_privacy_policy
          });
          this.customerForm.patchValue({
            customer_privacy_policy:
              response.data.settings.customer_privacy_policy,
            customer_about_us: response.data.settings.about_us,
            customer_terms_conditions:
              response.data.settings.customer_terms_conditions
          });
          console.log(response.data);
          //this.myapp.showSuccessAlert = true;
          //this.myapp.successMsg = response.message;
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
  }

  //form settings submit function
  onSettingSubmit() {
    let canSubmit = true;
    /*if (this.amount_from_vendor.value >= 100) {
      canSubmit = false;
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please enter valid amount for Vendors";
      return false;
    }*/

    if (this.amount_to_driver.value >= 100) {
      canSubmit = false;
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please enter valid amount for Drivers";
      return false;
    }

    if (this.tax_rate.value >= 100) {
      canSubmit = false;
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please enter valid value for Tax rate";
      return false;
    }

    if (canSubmit) {
      this.myapp.hideAlerts();
      this.myapp.spinner.show();
      const formData = new FormData();
      formData.append("tax_rate", this.settingsForm.get("tax_rate").value);
      formData.append(
        "contact_phone",
        this.settingsForm.get("contact_phone").value
      );
      formData.append(
        "contact_email",
        this.settingsForm.get("contact_email").value
      );
      formData.append(
        "amount_from_vendor",
        this.settingsForm.get("amount_from_vendor").value
      );
      formData.append(
        "amount_to_driver",
        this.settingsForm.get("amount_to_driver").value
      );
      formData.append("setting_type", "basic_settings");
      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);
      console.log(formData);
      //console.log("Service Image:" + this.service_image.value);
      this.api.callAPI(formData, "admin/updateSettings").subscribe(response => {
        if (response.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          // this.router.navigate(["../admin/settings"]);
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
    }
  }

  //form submit function
  onCustomerSettingSubmit() {
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append(
      "customer_terms_conditions",
      this.customerForm.get("customer_terms_conditions").value
    );
    formData.append(
      "customer_privacy_policy",
      this.customerForm.get("customer_privacy_policy").value
    );
    formData.append(
      "customer_about_us",
      this.customerForm.get("customer_about_us").value
    );
    formData.append("setting_type", "customer");
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    console.log(formData);
    //console.log("Service Image:" + this.service_image.value);
    this.api.callAPI(formData, "admin/updateSettings").subscribe(response => {
      if (response.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        // this.router.navigate(["../admin/app-settings"]);
      } else {
        this.myapp.spinner.hide();
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //form submit function
  onVendorSettingSubmit() {
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append(
      "vendor_terms_conditions",
      this.vendorForm.get("vendor_terms_conditions").value
    );
    formData.append(
      "vendor_privacy_policy",
      this.vendorForm.get("vendor_privacy_policy").value
    );
    formData.append("setting_type", "vendor");
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    console.log(formData);
    //console.log("Service Image:" + this.service_image.value);
    this.api.callAPI(formData, "admin/updateSettings").subscribe(response => {
      if (response.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        // this.router.navigate(["../admin/app-settings"]);
      } else {
        this.myapp.spinner.hide();
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //form submit function
  onDriverSettingSubmit() {
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append(
      "driver_terms_conditions",
      this.driverForm.get("driver_terms_conditions").value
    );
    formData.append(
      "driver_privacy_policy",
      this.driverForm.get("driver_privacy_policy").value
    );
    formData.append("setting_type", "driver");
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    console.log(formData);
    //console.log("Service Image:" + this.service_image.value);
    this.api.callAPI(formData, "admin/updateSettings").subscribe(response => {
      if (response.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        // this.router.navigate(["../admin/app-settings"]);
      } else {
        this.myapp.spinner.hide();
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.settingsForm.reset();
    //Patch the required data in Form Modal
    this.settingsForm.patchValue({
      setting_type: "",
      tax_rate: "",
      contact_phone: "",
      contact_email: "",
      amount_from_vendor: "",
      amount_to_driver: ""
    });

    this.customerForm.reset();
    //Patch the required data in Form Modal
    this.customerForm.patchValue({
      setting_type: "",
      customer_terms_conditions: "",
      customer_privacy_policy: "",
      customer_about_us: ""
    });

    this.vendorForm.reset();
    //Patch the required data in Form Modal
    this.vendorForm.patchValue({
      setting_type: "",
      vendor_terms_conditions: "",
      vendor_privacy_policy: ""
    });

    this.driverForm.reset();
    //Patch the required data in Form Modal
    this.driverForm.patchValue({
      setting_type: "",
      driver_terms_conditions: "",
      driver_privacy_policy: ""
    });
  }

  //Getor - To access the fields directly in the form in HTML

  get tax_rate() {
    return this.settingsForm.get("tax_rate");
  }
  get contact_phone() {
    return this.settingsForm.get("contact_phone");
  }
  get contact_email() {
    return this.settingsForm.get("contact_email");
  }
  get amount_from_vendor() {
    return this.settingsForm.get("amount_from_vendor");
  }
  get amount_to_driver() {
    return this.settingsForm.get("amount_to_driver");
  }
  get driver_terms_conditions() {
    return this.driverForm.get("driver_terms_conditions");
  }
  get driver_privacy_policy() {
    return this.driverForm.get("driver_privacy_policy");
  }
  get vendor_terms_conditions() {
    return this.vendorForm.get("vendor_terms_conditions");
  }
  get vendor_privacy_policy() {
    return this.vendorForm.get("vendor_privacy_policy");
  }
  get customer_terms_conditions() {
    return this.customerForm.get("customer_terms_conditions");
  }
  get customer_privacy_policy() {
    return this.customerForm.get("customer_privacy_policy");
  }
  get customer_about_us() {
    return this.customerForm.get("customer_about_us");
  }
}
