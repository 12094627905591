import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-view-packages",
  templateUrl: "./view-packages.component.html",
  styleUrls: ["./view-packages.component.css"],
})
export class ViewPackagesComponent implements OnInit {
  public packages: any = [];
  pageHeading = "";
  public profilePath = null;
  filterForm: FormGroup;
  user = {};
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private spinner: NgxSpinnerService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.getAllPackages("");
    this.pageHeading = "Package Management";

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
    });
  }

  getAllPackages(keyword) {
    //this.spinner.show();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", keyword);

    this.api.callAPI(formData, "admin/getPackages").subscribe((response) => {
      if (response.status === true) {
        this.packages = response.data;
        //console.log(this.packages);
        //this.myapp.showSuccessAlert = true;
        //this.myapp.successMsg = response.message;
      } else {
        this.packages = [];
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //On Keyword filter
  filterData() {
    let keyword = this.filterForm.get("keyword").value;
    this.getAllPackages(keyword);
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.api.convertToDateTime(data, option);
  }
}
