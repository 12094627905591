import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
/*import {
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule
} from "@angular/material";*/

// Import library module
import { NgxEditorModule } from "ngx-editor";
import { NgxSpinnerModule } from "ngx-spinner";
import { AgmCoreModule } from "@agm/core";
import { NgxPaginationModule } from "ngx-pagination";
import { ImageCropperModule } from "ngx-image-cropper";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { DashboardComponent } from "./vendor/dashboard/dashboard.component";
import { AdminDashboardComponent } from "./admin/admin-dashboard/admin-dashboard.component";
import { ViewCustomersComponent } from "./admin/customer/view-customers/view-customers.component";
import { CustomerProfileComponent } from "./admin/customer/customer-profile/customer-profile.component";
import { AddVendorComponent } from "./admin/vendor/add-vendor/add-vendor.component";
import { ViewVendorsComponent } from "./admin/vendor/view-vendors/view-vendors.component";
import { VendorProfileComponent } from "./admin/vendor/vendor-profile/vendor-profile.component";
import { AddDriverComponent } from "./admin/driver/add-driver/add-driver.component";
import { ViewDriversComponent } from "./admin/driver/view-drivers/view-drivers.component";
import { DriverProfileComponent } from "./admin/driver/driver-profile/driver-profile.component";
import { ViewOrdersComponent } from "./admin/order/view-orders/view-orders.component";
import { OrderDetailComponent } from "./admin/order/order-detail/order-detail.component";
import { AssignDriverComponent } from "./admin/order/assign-driver/assign-driver.component";
import { ViewPackagesComponent } from "./admin/package/view-packages/view-packages.component";
import { AddPackageComponent } from "./admin/package/add-package/add-package.component";
import { PackageDetailComponent } from "./admin/package/package-detail/package-detail.component";
import { ViewServicesComponent } from "./admin/service/view-services/view-services.component";
import { AddServiceComponent } from "./admin/service/add-service/add-service.component";
import { AppSettingComponent } from "./admin/setting/app-setting/app-setting.component";
import { CustomerTransactionComponent } from "./admin/transaction/customer-transaction/customer-transaction.component";
import { VendorTransactionComponent } from "./admin/transaction/vendor-transaction/vendor-transaction.component";
import { DriverTransactionComponent } from "./admin/transaction/driver-transaction/driver-transaction.component";
import { AddTransactionComponent } from "./admin/transaction/add-transaction/add-transaction.component";
import { ManageBoxesComponent } from "./admin/service/manage-boxes/manage-boxes.component";
import { ViewServiceItemsComponent } from "./admin/service/view-service-items/view-service-items.component";
import { EditServiceItemsComponent } from "./admin/service/edit-service-items/edit-service-items.component";
import { ManageBannersComponent } from "./admin/setting/manage-banners/manage-banners.component";
import { ManageReportsComponent } from "./admin/setting/manage-reports/manage-reports.component";
import { NotificationComponent } from "./admin/notification/notification.component";
import { EditPackageComponent } from "./admin/package/edit-package/edit-package.component";
import { EditOrderComponent } from "./admin/order/edit-order/edit-order.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { TransactionsComponent } from "./vendor/transactions/transactions.component";
import { VendorSettingsComponent } from "./vendor/vendor-settings/vendor-settings.component";
import { VendorNotificationComponent } from "./vendor/vendor-notification/vendor-notification.component";
import { VendorDriverProfileComponent } from "./vendor/driver/vendor-driver-profile/vendor-driver-profile.component";
import { VendorAddDriverComponent } from "./vendor/driver/vendor-add-driver/vendor-add-driver.component";
import { VendorViewDriversComponent } from "./vendor/driver/vendor-view-drivers/vendor-view-drivers.component";
import { VendorViewOrderComponent } from "./vendor/order/vendor-view-order/vendor-view-order.component";
import { VendorEditOrderComponent } from "./vendor/order/vendor-edit-order/vendor-edit-order.component";
import { VendorOrderDetailComponent } from "./vendor/order/vendor-order-detail/vendor-order-detail.component";
import { EmailComponent } from "./vendor/profile/change-email/email/email.component";
import { PhoneComponent } from "./vendor/profile/change-phone/phone/phone.component";
import { VerifyPhoneOtpComponent } from "./vendor/profile/change-phone/verify-phone-otp/verify-phone-otp.component";
import { VerifyEmailOtpComponent } from "./vendor/profile/change-email/verify-email-otp/verify-email-otp.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    AdminDashboardComponent,
    ViewCustomersComponent,
    CustomerProfileComponent,
    AddVendorComponent,
    ViewVendorsComponent,
    VendorProfileComponent,
    AddDriverComponent,
    ViewDriversComponent,
    DriverProfileComponent,
    ViewOrdersComponent,
    OrderDetailComponent,
    AssignDriverComponent,
    ViewPackagesComponent,
    AddPackageComponent,
    PackageDetailComponent,
    ViewServicesComponent,
    AddServiceComponent,
    AppSettingComponent,
    CustomerTransactionComponent,
    VendorTransactionComponent,
    DriverTransactionComponent,
    AddTransactionComponent,
    ManageBoxesComponent,
    ViewServiceItemsComponent,
    EditServiceItemsComponent,
    ManageBannersComponent,
    ManageReportsComponent,
    NotificationComponent,
    EditPackageComponent,
    EditOrderComponent,
    ChangePasswordComponent,
    TransactionsComponent,
    VendorSettingsComponent,
    VendorNotificationComponent,
    VendorDriverProfileComponent,
    VendorAddDriverComponent,
    VendorViewDriversComponent,
    VendorViewOrderComponent,
    VendorEditOrderComponent,
    VendorOrderDetailComponent,
    EmailComponent,
    PhoneComponent,
    VerifyPhoneOtpComponent,
    VerifyEmailOtpComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    ImageCropperModule,
    NgxEditorModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCIdBkAu1rREUHekmzQFIUllpI7qBCvdOo", //"AIzaSyAiJlRv6H0jkz6Q_MLgwvBMYP35K4Ma0OI",
      libraries: ["places"]
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
