import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";
import { ConcatSource } from "webpack-sources";

@Component({
  selector: "app-driver-transaction",
  templateUrl: "./driver-transaction.component.html",
  styleUrls: ["./driver-transaction.component.css"]
})
export class DriverTransactionComponent implements OnInit {
  public driverTrans: any = [];
  page = 1; //used for load more
  hideLoadMore = true;
  successMessage = "";
  failMessage = "";
  pageHeading = "";
  filterForm: FormGroup;

  public profilePath = null;
  user = {};
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private spinner: NgxSpinnerService,
    private api: ApiService
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""]
    });

    this.getDriverTrans("");
    this.pageHeading = "Driver Transactions";
  }

  getDriverTrans(keyword) {
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", keyword);
    this.api
      .callAPI(formData, "admin/getDriverTransactions")
      .subscribe(response => {
        if (response.status === true) {
          this.driverTrans = response.data;
          //console.log(this.driverTrans);
          // this.successMessage = response.message;
        } else {
          this.driverTrans = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      });
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", this.filterForm.get("keyword").value);
    formData.append("page", pageNo);

    this.api
      .callAPI(formData, "admin/getDriverTransactions")
      .subscribe((response: any) => {
        if (response.status == true) {
          this.myapp.spinner.hide();
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;
          //console.log("Length:" + response.data.length);
          for (let i = 0; i < response.data.length; i++) {
            this.driverTrans.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.spinner.hide();
          this.myapp.isLoadingResults = false;
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
          this.hideLoadMore = false;
        }
      });
  }

  //On Keyword filter
  filterData() {
    this.hideLoadMore = true;
    this.page = 1;

    let keyword = this.filterForm.get("keyword").value;
    this.getDriverTrans(keyword);
  }
}
