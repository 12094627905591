import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AppComponent } from "../../../app.component";
import { ApiService } from "../../../service/api.service";

@Component({
  selector: "app-add-service",
  templateUrl: "./add-service.component.html",
  styleUrls: ["./add-service.component.css"]
})
export class AddServiceComponent implements OnInit {
  //VAriable declaration
  public pageHeading = "Add Service";
  imgPreviewPath = null;
  FileErrorMessageLog = false;
  showBoxTypes = false;
  showItemTypes = false;
  showDynamicItems = false;
  smallBoxPriceError = false;
  mediumBoxPriceError = false;
  largeBoxPriceError = false;
  smallBoxErrorMsg = "";
  mediumBoxErrorMsg = "";
  largeBoxErrorMsg = "";

  smallBoxVendorPriceError = false;
  mediumBoxVendorPriceError = false;
  largeBoxVendorPriceError = false;
  smallBoxVendorErrorMsg = "";
  mediumBoxVendorErrorMsg = "";
  largeBoxVendorErrorMsg = "";

  itemError = false;
  priceError = false;
  vendorPriceError = false;
  dynamicItemsError = false;
  boxes = [];
  smallBoxWeight = null;
  mediumBoxWeight = null;
  largeBoxWeight = null;
  chooseFileLabel = "Choose a file...";
  public serviceForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}

  ngOnInit() {
    /*this.myapp.spinner.show();
    setTimeout(() => {
      this.myapp.spinner.hide();
    }, 2000);*/
    this.getAllBoxes();
    //Form Model Declaration with form validation for the fields
    this.serviceForm = this.fb.group({
      service_id: [""],
      service_name: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z ()]+$"),
          Validators.minLength(3),
          Validators.maxLength(50)
        ]
      ],
      service_type: ["", [Validators.required]],
      service_image: [""],
      small_box_price: [""],
      medium_box_price: [""],
      large_box_price: [""],
      small_box_vendor_price: [""],
      medium_box_vendor_price: [""],
      large_box_vendor_price: [""],
      item: [""],
      price: [""],
      vendor_price: [""],
      item_names: this.fb.array([]),
      item_price: this.fb.array([]),
      item_vendor_price: this.fb.array([])
    });
  }

  getAllBoxes() {
    this.api
      .callAPI(this.myapp.loginUser, "admin/getAllBoxes")
      .subscribe(response => {
        if (response.status === true) {
          this.boxes = response.data;
          this.smallBoxWeight =
            this.boxes[0]["min_qty"] +
            " to " +
            this.boxes[0]["max_qty"] +
            " KG";

          this.mediumBoxWeight =
            this.boxes[1]["min_qty"] +
            " to " +
            this.boxes[1]["max_qty"] +
            " KG";

          this.largeBoxWeight =
            this.boxes[2]["min_qty"] +
            " to " +
            this.boxes[2]["max_qty"] +
            " KG";
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
  }

  //on File select, put the selected file in Form model variable
  onFileSelect(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // console.log("files" + files);
      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      if (fileType === "image") {
        this.serviceForm.get("service_image").setValue(file);
        this.FileErrorMessageLog = false;
        //Preveiw of the image only on images
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          this.imgPreviewPath = reader.result;
        };
        return;
      } else {
        this.FileErrorMessageLog = true;
      }
    }
  }

  // Functoin to Show/Hide box-items divs
  showHideBoxItems(type: any) {
    if (type == "box") {
      this.showBoxTypes = true;
      this.showItemTypes = false;
    } else {
      this.showBoxTypes = false;
      this.showItemTypes = true;
    }
  }

  //form submit function
  onFormSubmit() {
    let canSubmit = true;
    this.smallBoxPriceError = false;
    this.mediumBoxPriceError = false;
    this.largeBoxPriceError = false;

    this.smallBoxVendorPriceError = false;
    this.mediumBoxVendorPriceError = false;
    this.largeBoxVendorPriceError = false;
    this.itemError = false;

    let small_box_price = this.small_box_price.value;
    let medium_box_price = this.medium_box_price.value;
    let large_box_price = this.large_box_price.value;

    let small_box_vendor_price = this.small_box_vendor_price.value;
    let medium_box_vendor_price = this.medium_box_vendor_price.value;
    let large_box_vendor_price = this.large_box_vendor_price.value;

    if (this.service_type.value == "box") {
      if (
        !Number(small_box_price) ||
        small_box_price == "" ||
        small_box_price <= 0
      ) {
        this.smallBoxPriceError = true;
        this.smallBoxErrorMsg = "Please enter valid price";
        canSubmit = false;
      }
      if (
        !Number(medium_box_price) ||
        medium_box_price == "" ||
        medium_box_price <= 0
      ) {
        this.mediumBoxPriceError = true;
        this.mediumBoxErrorMsg = "Please enter valid price";
        canSubmit = false;
      }
      if (
        !Number(large_box_price) ||
        large_box_price == "" ||
        large_box_price <= 0
      ) {
        this.largeBoxPriceError = true;
        this.largeBoxErrorMsg = "Please enter valid price";
        canSubmit = false;
      }

      //Vendor price validation
      if (
        !Number(small_box_vendor_price) ||
        small_box_vendor_price == "" ||
        small_box_vendor_price <= 0
      ) {
        this.smallBoxVendorPriceError = true;
        this.smallBoxVendorErrorMsg = "Please enter valid price";
        canSubmit = false;
      }
      if (
        !Number(medium_box_vendor_price) ||
        medium_box_vendor_price == "" ||
        medium_box_vendor_price <= 0
      ) {
        this.mediumBoxVendorPriceError = true;
        this.mediumBoxVendorErrorMsg = "Please enter valid price";
        canSubmit = false;
      }
      if (
        !Number(large_box_vendor_price) ||
        large_box_vendor_price == "" ||
        large_box_vendor_price <= 0
      ) {
        this.largeBoxVendorPriceError = true;
        this.largeBoxVendorErrorMsg = "Please enter valid price";
        canSubmit = false;
      }
    } else {
      var letters = /^[A-Za-z]+$/;
      if (this.item.value == "" || !this.item.value.match(letters)) {
        this.itemError = true;
        canSubmit = false;
      }
      if (
        !Number(this.price.value) ||
        this.price.value == "" ||
        this.price.value <= 0
      ) {
        this.priceError = true;
        canSubmit = false;
      }
      if (
        !Number(this.vendor_price.value) ||
        this.vendor_price.value == "" ||
        this.vendor_price.value <= 0
      ) {
        this.vendorPriceError = true;
        canSubmit = false;
      }

      /*if (this.item_names.length != this.item_price.length) {
        this.dynamicItemsError = true;
        canSubmit = false;
      }*/
      //console.log("length:" + this.item_names.length);
      //console.log("price length:" + this.item_price.length);
    }

    /*if (canSubmit) console.log("Can submit");
    else console.log("not submit");*/

    if (canSubmit) {
      this.myapp.spinner.show();
      const formData = new FormData();
      formData.append("service_id", this.serviceForm.get("service_id").value);
      formData.append("service_name", this.service_name.value);
      formData.append("service_type", this.service_type.value);
      formData.append("service_image", this.service_image.value);
      formData.append("small_box_id", "1");
      formData.append("medium_box_id", "2");
      formData.append("large_box_id", "3");
      formData.append("small_box_price", this.small_box_price.value);
      formData.append("medium_box_price", this.medium_box_price.value);
      formData.append("large_box_price", this.large_box_price.value);

      formData.append(
        "small_box_vendor_price",
        this.small_box_vendor_price.value
      );
      formData.append(
        "medium_box_vendor_price",
        this.medium_box_vendor_price.value
      );
      formData.append(
        "large_box_vendor_price",
        this.large_box_vendor_price.value
      );

      formData.append("item", this.item.value);
      formData.append("price", this.price.value);
      formData.append("vendor_price", this.vendor_price.value);
      formData.append("item_names", this.item_names.value);
      formData.append("item_price", this.item_price.value);
      formData.append("item_vendor_price", this.item_vendor_price.value);

      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);

      //console.log("Service Image:" + this.service_image.value);
      this.api.callAPI(formData, "admin/addService").subscribe(response => {
        if (response.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.router.navigate(["../admin/view-services"]);
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
    }
  }

  //Reset the Form via Cancel Button
  resetForm() {
    //this.serviceForm.reset();
    //Patch the required data in Form Modal
    this.serviceForm.patchValue({
      service_id: "",
      service_name: "",
      service_image: "",
      service_type: "",
      small_box_price: "",
      medium_box_price: "",
      large_box_price: "",
      small_box_vendor_price: "",
      medium_box_vendor_price: "",
      large_box_vendor_price: "",
      item: "",
      price: "",
      vendor_price: ""
      //item_names: [],
      //item_price: []
    });

    /*let i = 0;
    while (this.item_names.length !== 0) {
      this.item_names.removeAt(i);
      i++;
    }*/

    this.imgPreviewPath = null;
    this.FileErrorMessageLog = false;
    this.showBoxTypes = false;
    this.showItemTypes = false;
    this.showDynamicItems = false;
    this.smallBoxPriceError = false;
    this.mediumBoxPriceError = false;
    this.largeBoxPriceError = false;
    this.itemError = false;
    this.priceError = false;
    this.vendorPriceError = false;
    this.dynamicItemsError = false;
  }

  //Getor - To access the fields directly in the form in HTML
  get service_name() {
    return this.serviceForm.get("service_name");
  }
  get service_type() {
    return this.serviceForm.get("service_type");
  }
  get service_image() {
    return this.serviceForm.get("service_image");
  }
  get small_box_price() {
    return this.serviceForm.get("small_box_price");
  }
  get medium_box_price() {
    return this.serviceForm.get("medium_box_price");
  }
  get large_box_price() {
    return this.serviceForm.get("large_box_price");
  }
  get small_box_vendor_price() {
    return this.serviceForm.get("small_box_vendor_price");
  }
  get medium_box_vendor_price() {
    return this.serviceForm.get("medium_box_vendor_price");
  }
  get large_box_vendor_price() {
    return this.serviceForm.get("large_box_vendor_price");
  }
  get item() {
    return this.serviceForm.get("item");
  }
  get price() {
    return this.serviceForm.get("price");
  }
  get vendor_price() {
    return this.serviceForm.get("vendor_price");
  }
  get item_names() {
    return this.serviceForm.get("item_names") as FormArray;
  }
  get item_price() {
    return this.serviceForm.get("item_price") as FormArray;
  }
  get item_vendor_price() {
    return this.serviceForm.get("item_vendor_price") as FormArray;
  }

  // Function to add new row of items
  addNewItem() {
    this.showDynamicItems = true;
    this.item_names.push(
      this.fb.control("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z ()]+$"),
        Validators.minLength(2),
        Validators.maxLength(30)
      ])
    );
    this.item_price.push(
      this.fb.control("", [
        Validators.required,
        Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/),
        Validators.minLength(1),
        Validators.maxLength(4)
      ])
    );
    this.item_vendor_price.push(
      this.fb.control("", [
        Validators.required,
        Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/),
        Validators.minLength(1),
        Validators.maxLength(4)
      ])
    );
    this.dynamicItemsError = true;
  }

  // Function to delete row of items
  deleteItem(index: number) {
    this.item_names.removeAt(index);
    this.item_price.removeAt(index);
    this.item_vendor_price.removeAt(index);
    //console.log("length:" + this.item_names.length);
    if (this.item_names.length == 0) this.dynamicItemsError = false;
  }
}
