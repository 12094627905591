import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/service/api.service";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-edit-service-items",
  templateUrl: "./edit-service-items.component.html",
  styleUrls: ["./edit-service-items.component.css"]
})
export class EditServiceItemsComponent implements OnInit {
  public serviceForm: FormGroup;
  public boxForm: FormGroup;
  public itemForm: FormGroup;
  public service = null;
  public items = [];
  public itemsToDel: any = [];
  service_id = null;
  imgPreviewPath = null;
  public serviceImgPath = this.api.serviceImgPath();
  FileErrorMessageLog = false;
  smallBoxWeight = null;
  mediumBoxWeight = null;
  largeBoxWeight = null;
  smallBoxPriceError = false;
  mediumBoxPriceError = false;
  largeBoxPriceError = false;
  dynamicItemsError = false;
  chooseFileLabel = "Choose a file...";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private api: ApiService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.service_id = id;
        this.getServiceItems(id);
      }
    });

    //Form Model Declaration with form validation for the fields
    this.serviceForm = this.fb.group({
      service_id: [this.service_id],
      service_name: [
        "",
        [
          Validators.required,
          //Validators.pattern("^[a-zA-Z ()]+$"),
          Validators.minLength(3),
          Validators.maxLength(50)
        ]
      ],
      service_image: [""]
    });

    this.boxForm = this.fb.group({
      small_item_id: [""],
      small_box_price: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(4),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      small_box_vendor_price: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(4),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      medium_item_id: [""],
      medium_box_price: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(4),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      medium_box_vendor_price: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(4),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      large_item_id: [""],
      large_box_price: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(4),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      large_box_vendor_price: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(4),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ]
    });

    this.itemForm = this.fb.group({
      item_ids: this.fb.array([]),
      item_names: this.fb.array([]),
      item_price: this.fb.array([]),
      item_vendor_price: this.fb.array([])
    });
  }

  //Function to load data from DB
  getServiceItems(id) {
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("service_id", id);

    this.api.callAPI(formData, "admin/getServiceDetail").subscribe(response => {
      if (response.status === true) {
        this.service = response.data.service;
        this.items = response.data.items;
        this.serviceForm.patchValue({
          service_name: this.service.service_name,
          service_image: this.service.service_image
        });
        if (this.service.service_image)
          this.imgPreviewPath =
            this.serviceImgPath + this.service.service_image;

        //patch the Items
        if (this.service.service_type == "box") {
          this.boxForm.patchValue({
            small_item_id: this.items[0]["item_id"],
            small_box_price: this.items[0]["price"],
            small_box_vendor_price: this.items[0]["vendor_price"],
            medium_item_id: this.items[1]["item_id"],
            medium_box_price: this.items[1]["price"],
            medium_box_vendor_price: this.items[1]["vendor_price"],
            large_item_id: this.items[2]["item_id"],
            large_box_price: this.items[2]["price"],
            large_box_vendor_price: this.items[2]["vendor_price"]
          });

          this.smallBoxWeight =
            this.items[0]["min_qty"] +
            " to " +
            this.items[0]["max_qty"] +
            " KG";

          this.mediumBoxWeight =
            this.items[1]["min_qty"] +
            " to " +
            this.items[1]["max_qty"] +
            " KG";

          this.largeBoxWeight =
            this.items[2]["min_qty"] +
            " to " +
            this.items[2]["max_qty"] +
            " KG";
        } else {
          for (let i = 0; i < this.items.length; i++) {
            //console.log("Items", this.items[i]);
            this.item_ids.push(this.fb.control(this.items[i]["item_id"]));
            this.item_names.push(
              this.fb.control(this.items[i]["item_name"], [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(30),
                Validators.pattern("^[a-zA-Z ()]+$")
              ])
            );
            this.item_price.push(
              this.fb.control(this.items[i]["price"], [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(4),
                Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
              ])
            );
            this.item_vendor_price.push(
              this.fb.control(this.items[i]["vendor_price"], [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(4),
                Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
              ])
            );
          }
        }
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //on File select, put the selected file in Form model variable
  onFileSelect(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // console.log("files" + files);
      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      if (fileType === "image") {
        this.serviceForm.get("service_image").setValue(file);
        this.FileErrorMessageLog = false;
        //Preveiw of the image only on images
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          this.imgPreviewPath = reader.result;
        };
        return;
      } else {
        this.FileErrorMessageLog = true;
      }
    }
  }

  //form submit function
  onServiceFormSubmit() {
    this.myapp.hideAlerts();
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("service_id", this.service_id);
    formData.append("service_name", this.service_name.value);
    formData.append("service_image", this.service_image.value);

    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);

    //console.log("Service Image:" + this.service_image.value);
    this.api.callAPI(formData, "admin/editService").subscribe(response => {
      if (response.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
      } else {
        this.myapp.spinner.hide();
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //box item form submit
  onServiceBoxSubmit() {
    this.smallBoxPriceError = false;
    this.mediumBoxPriceError = false;
    this.largeBoxPriceError = false;
    this.myapp.hideAlerts();

    let canSubmit = true;
    if (this.small_box_price.value == "" || this.small_box_price.value <= 0) {
      this.smallBoxPriceError = true;
      canSubmit = false;
    }
    if (this.medium_box_price.value == "" || this.medium_box_price.value <= 0) {
      this.mediumBoxPriceError = true;
      canSubmit = false;
    }
    if (this.large_box_price.value == "" || this.large_box_price.value <= 0) {
      this.largeBoxPriceError = true;
      canSubmit = false;
    }

    if (canSubmit) {
      this.myapp.spinner.show();
      const formData = new FormData();
      formData.append("service_id", this.service_id);
      formData.append("service_type", "box");
      formData.append("small_item_id", this.boxForm.get("small_item_id").value);
      formData.append("small_box_price", this.small_box_price.value);
      formData.append(
        "small_box_vendor_price",
        this.small_box_vendor_price.value
      );
      formData.append(
        "medium_item_id",
        this.boxForm.get("medium_item_id").value
      );
      formData.append("medium_box_price", this.medium_box_price.value);
      formData.append(
        "medium_box_vendor_price",
        this.medium_box_vendor_price.value
      );
      formData.append("large_item_id", this.boxForm.get("large_item_id").value);
      formData.append("large_box_price", this.large_box_price.value);
      formData.append(
        "large_box_vendor_price",
        this.large_box_vendor_price.value
      );

      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);

      //console.log("Service Image:" + this.service_image.value);
      this.api
        .callAPI(formData, "admin/editServiceItems")
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }

  //box item form submit
  onItemFormSubmit() {
    //this.myapp.hideAlerts();
    let canSubmit = true;
    if (canSubmit) {
      this.myapp.spinner.show();
      const formData = new FormData();
      formData.append("service_id", this.service_id);
      formData.append("service_type", "piece");
      formData.append("item_ids", this.item_ids.value);
      formData.append("item_names", this.item_names.value);
      formData.append("item_price", this.item_price.value);
      formData.append("item_vendor_price", this.item_vendor_price.value);
      formData.append("itemToDel", this.itemsToDel);

      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);

      //console.log("Service Image:" + this.service_image.value);
      this.api
        .callAPI(formData, "admin/editServiceItems")
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }

  //Getor - To access the fields directly in the form in HTML
  get service_name() {
    return this.serviceForm.get("service_name");
  }
  get service_image() {
    return this.serviceForm.get("service_image");
  }

  get small_box_price() {
    return this.boxForm.get("small_box_price");
  }
  get medium_box_price() {
    return this.boxForm.get("medium_box_price");
  }
  get large_box_price() {
    return this.boxForm.get("large_box_price");
  }

  get small_box_vendor_price() {
    return this.boxForm.get("small_box_vendor_price");
  }
  get medium_box_vendor_price() {
    return this.boxForm.get("medium_box_vendor_price");
  }
  get large_box_vendor_price() {
    return this.boxForm.get("large_box_vendor_price");
  }

  get item_ids() {
    return this.itemForm.get("item_ids") as FormArray;
  }
  get item_names() {
    return this.itemForm.get("item_names") as FormArray;
  }
  get item_price() {
    return this.itemForm.get("item_price") as FormArray;
  }
  get item_vendor_price() {
    return this.itemForm.get("item_vendor_price") as FormArray;
  }

  // Function to add new row of items
  addNewItem() {
    this.item_ids.push(this.fb.control("0"));
    this.item_names.push(
      this.fb.control("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z ()]+$"),
        Validators.minLength(2),
        Validators.maxLength(30)
      ])
    );
    this.item_price.push(
      this.fb.control("", [
        Validators.required,
        Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/),
        Validators.minLength(1),
        Validators.maxLength(4)
      ])
    );
    this.item_vendor_price.push(
      this.fb.control("", [
        Validators.required,
        Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/),
        Validators.minLength(1),
        Validators.maxLength(4)
      ])
    );
    this.dynamicItemsError = true;
  }

  // Function to delete row of items
  deleteItem(index: number) {
    let conf = confirm(
      "Do you want this? it will delete the items from the database also."
    );
    if (conf) {
      this.itemsToDel.push(this.item_ids.value[index]);
      this.item_ids.removeAt(index);
      this.item_names.removeAt(index);
      this.item_price.removeAt(index);
      this.item_vendor_price.removeAt(index);
      //console.log("delete items:", this.itemsToDel);
      if (this.item_names.length == 0) this.dynamicItemsError = false;
    }
  }

  checkPriceLimit(event) {
    let value = event.target.value;
    //console.log(event.target.value);
    if (value.length >= 4) {
      //event.preventDefault();
      let number = value.substring(0, 4);
      event.target.value = number;
    }
  }
}
