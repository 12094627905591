import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { ApiService } from "src/app/service/api.service";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { interval, Subscription } from "rxjs";

@Component({
  selector: "app-vendor-notification",
  templateUrl: "./vendor-notification.component.html",
  styleUrls: ["./vendor-notification.component.css"]
})
export class VendorNotificationComponent implements OnInit {
  pageHeading = "";
  public adminMenu = false;
  isRead = true;
  page = 1; //used for load more
  hideLoadMore = true;
  isDelete = true;
  public count: any = [];
  public vendorMenu = false;
  mySubscription: Subscription;
  notification: any = [];
  user = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    public spinner: NgxSpinnerService,
    public api: ApiService
  ) {}

  ngOnInit() {
    // //in 10 seconds do something
    // this.mySubscription = interval(30000).subscribe((x) => {
    //   this.getNotifications("all");
    // });

    this.pageHeading = "Notification Management";
    this.getNotifications("all");
    if (window.localStorage.getItem("role_id") == "1") {
      this.adminMenu = true;
    } else {
      this.vendorMenu = true;
    }
  }

  getNotifications(all) {
    this.user = {
      vendor_id: this.myapp.vendorUserId,
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      noti_type: all
    };
    // this.myapp.spinner.show();
    this.api
      .callAPI(this.user, "vendor/getNotification")
      .subscribe(response => {
        if (response.status === true) {
          // this.myapp.spinner.hide();
          this.notification = response.data;
          console.log(this.notification);
        } else {
          this.notification = [];
          this.count = [];
          this.isDelete = false;
          this.isRead = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
  }

  delete(vendor_noti_id) {
    this.user = {
      vendor_id: this.myapp.vendorUserId,
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      vendor_noti_id: vendor_noti_id
    };
    let conf = confirm("Do you want to perform this action?");
    if (conf) {
      this.spinner.show();
      this.api
        .callAPI(this.user, "vendor/deleteNotifications")
        .subscribe(response => {
          this.spinner.hide();
          if (response.status === true) {
            this.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.getNotifications("all");
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }

  markAllRead() {
    let conf = confirm("Do you want to perform this action?");
    if (conf) {
      this.spinner.show();
      this.api
        .callAPI(
          this.myapp.loginVendorUser,
          "vendor/markAllNotificationAsReads"
        )
        .subscribe(response => {
          this.spinner.hide();
          if (response.status === true) {
            this.spinner.hide();

            this.myapp.notificationCount = 0;
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.getNotifications("all");
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }

  clearAll() {
    let conf = confirm("Do you want to perform this action?");
    if (conf) {
      this.api
        .callAPI(this.myapp.loginVendorUser, "vendor/clearNotification")
        .subscribe(response => {
          if (response.status === true) {
            // this.spinner.hide();
            // this.myapp.showSuccessAlert = true;
            // this.myapp.successMsg = response.message;
            this.isDelete = false;
            this.count = [];
            this.isRead = false;
            this.notification = [];
            this.myapp.notificationCount = 0;
          }
        });
    }
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    console.log(pageNo);
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("vendor_id", this.myapp.vendorUserId);
    formData.append("token", this.myapp.token);
    formData.append("noti_type", "all");
    formData.append("page", pageNo);
    this.api
      .callAPI(formData, "vendor/getNotification")
      .subscribe((response: any) => {
        if (response.status == true) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;
          for (let i = 0; i < response.data.length; i++) {
            this.notification.push(response.data[i]);
          }
          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
          this.hideLoadMore = false;
        }
      });
  }
}
