import { Component, OnInit } from "@angular/core";
import { ParamMap, Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-vendor-driver-profile",
  templateUrl: "./vendor-driver-profile.component.html",
  styleUrls: ["./vendor-driver-profile.component.css"]
})
export class VendorDriverProfileComponent implements OnInit {
  pageHeading = "";
  user = {};
  filterForm: FormGroup;
  userChangeStatus = {};
  driver: any = [];
  amount: any = [];
  driver_order_data = [];
  driver_transactions = [];
  driver_info: any = [];
  failMessage = "";
  successMessage = "";
  suspendUser = false;
  suspendReason = "";
  driverId = 0;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public api: ApiService
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""]
    });

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      this.driverId = id;
      if (id) {
        this.loadUserProfile(id);
        this.pageHeading = "Driver Profile";
      }
    });
  }
  loadUserProfile(id) {
    this.user = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      driver_user_id: id
    };
    this.api
      .callAPI(this.user, "vendor/getDriverProfile")
      .subscribe(response => {
        if (response.status === true) {
          this.driver_transactions = response.data.driver_transactions;
          this.driver_order_data = response.data.driver_order_data;
          this.driver_info = response.data.driver_data;
          this.amount = response.data.wallet_amount;
          console.log(response.data);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          console.log(response.message);
        }
      });
  }

  showSuspendBox() {
    this.suspendUser = true;
  }

  cancelSuspend() {
    this.suspendUser = false;
    this.suspendReason = "";
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.api.convertToDateTime(data, option);
  }

  changeStatus(id, value) {
    //console.log("REason", this.suspendReason);
    this.userChangeStatus = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      status: value,
      driver_user_id: id,
      reason: this.suspendReason
    };

    this.spinner.show();
    this.api
      .callAPI(this.userChangeStatus, "vendor/driverChangeStatus")
      .subscribe(response => {
        this.spinner.hide();
        if (response.status === true) {
          this.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          //this.router.navigate(["../admin/driver-profile/" + id]);
          this.driver_info["status"] = value;
          this.suspendUser = false;
          if (value == 2)
            this.driver_info["suspend_reason"] = this.suspendReason;
          else {
            this.driver_info["suspend_reason"] = null;
            this.suspendReason = "";
          }
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
  }

  //On Keyword filter
  filterData(driverId) {
    this.myapp.showErrorAlert = false;
    this.myapp.errorMsg = "";
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", this.filterForm.get("keyword").value);
    formData.append("driver_user_id", driverId);

    this.api.callAPI(formData, "vendor/getFilterOrder").subscribe(response => {
      if (response.status === true) {
        this.driver_order_data = response.data.driver_order_data;
        //console.log(response.data);
      } else {
        this.driver_order_data = [];
        //this.myapp.showErrorAlert = true;
        //this.myapp.errorMsg = response.message;
        //console.log(response.message);
      }
    });
  }
}
