import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-transactions",
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.css"]
})
export class TransactionsComponent implements OnInit {
  transactions: any = [];
  page = 1; //used for load more
  hideLoadMore = true;
  successMessage = "";
  failMessage = "";
  filterForm: FormGroup;
  pageHeading = "";
  excelFilePath = null;
  public profilePath = null;
  user = {};
  file = "";
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private spinner: NgxSpinnerService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      start_date: [""],
      end_date: [""]
    });
    this.getAlltransactions("", "", "");
    this.pageHeading = "My Transactions";
  }

  getAlltransactions(start_date, end_date, exportData) {
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("vendor_id", this.myapp.vendorUserId);
    formData.append("token", this.myapp.token);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    formData.append("export", exportData);

    this.api
      .callAPI(formData, "vendor/getMyTransactions")
      .subscribe(response => {
        if (response.status === true) {
          this.transactions = response.data;
          this.excelFilePath = response.fileurl;
          console.log(this.transactions.length);
          console.log(response.limit);
          // this.successMessage = response.message;
        } else {
          this.transactions = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      });
  }

  exportToExcel(exportData) {
    this.getAlltransactions("", "", exportData);
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("vendor_id", this.myapp.vendorUserId);
    formData.append("token", this.myapp.token);
    formData.append("start_date", this.filterForm.get("start_date").value);
    formData.append("end_date", this.filterForm.get("end_date").value);
    formData.append("page", pageNo);

    this.api
      .callAPI(formData, "vendor/getMyTransactions")
      .subscribe((response: any) => {
        if (response.status == true) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;
          for (let i = 0; i < response.data.length; i++) {
            this.transactions.push(response.data[i]);
          }
          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
          this.hideLoadMore = false;
        }
      });
  }

  onFilterSubmit() {
    this.hideLoadMore = true;
    this.page = 1;
    let start_date = this.filterForm.get("start_date").value;
    let end_date = this.filterForm.get("end_date").value;
    this.getAlltransactions(start_date, end_date, "");
  }
}
