import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { ApiService } from "src/app/service/api.service";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-manage-banners",
  templateUrl: "./manage-banners.component.html",
  styleUrls: ["./manage-banners.component.css"]
})
export class ManageBannersComponent implements OnInit {
  //VAriable declaration
  public pageHeading = "Add Service";
  imgPreviewPath = null;
  bannerImgPath = this.api.bannerImgPath();
  FileErrorMessageLog = false;
  banners: any = [];
  public bannerForm: FormGroup;
  imageChangedEvent: any = "";
  imageSave = false;
  imageHide = false;
  chooseFileLabel = "Choose a file...";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}

  ngOnInit() {
    //Form Model Declaration with form validation for the fields
    this.bannerForm = this.fb.group({
      banner_image: [""],
      base64Img: ["", [Validators.required]]
    });
    this.getAllBanners();
  }

  getAllBanners() {
    this.api
      .callAPI(this.myapp.loginUser, "admin/getBanners")
      .subscribe(response => {
        if (response.status === true) {
          this.banners = response.data;
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
  }

  //on File select, put the selected file in Form model variable
  onFileSelect(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // console.log("files" + files);
      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      if (fileType === "image") {
        this.imageHide = true;
        this.imageSave = true;

        this.bannerForm.get("banner_image").setValue(file);
        this.imageChangedEvent = event;
        this.FileErrorMessageLog = false;
        //Preveiw of the image only on images
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          this.imgPreviewPath = reader.result;
        };
        return;
      } else {
        this.FileErrorMessageLog = true;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log("Cropping: " + JSON.stringify(event));
    this.imgPreviewPath = event.base64;
    this.bannerForm.get("banner_image").setValue(event.base64);
    //Patch the required data in Form Modal
    this.bannerForm.patchValue({
      base64Img: "ok"
    });
  }

  saveCropImg() {
    this.imageSave = false;
  }

  //form submit function
  onFormSubmit() {
    this.myapp.hideAlerts();
    //this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("banner_image", this.banner_image.value);
    formData.append("base64Img", this.bannerForm.get("base64Img").value);
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);

    //console.log("Service Image:" + this.banner_image.value);
    this.api.callAPI(formData, "admin/addBanner").subscribe(response => {
      if (response.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.imgPreviewPath = null;
        this.getAllBanners();
        this.bannerForm.reset();
      } else {
        this.myapp.spinner.hide();
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  deleteBanner(index, banner_id) {
    let conf = confirm("Do you want to delete this baner?");
    if (conf) {
      this.myapp.hideAlerts();
      this.myapp.spinner.show();
      const formData = new FormData();
      formData.append("banner_id", banner_id);
      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);

      //console.log("Service Image:" + this.banner_image.value);
      this.api.callAPI(formData, "admin/deleteBanner").subscribe(response => {
        if (response.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.banners.splice(index, 1);
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
    }
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.api.convertToDateTime(data, option);
  }

  //Getor - To access the fields directly in the form in HTML
  get banner_image() {
    return this.bannerForm.get("banner_image");
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.bannerForm.reset();
    //Patch the required data in Form Modal
    this.bannerForm.patchValue({
      banner_image: "",
      base64Img: ""
    });

    this.imgPreviewPath = null;
    this.imageSave = false;
  }
}
