import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl
} from "@angular/forms";
import { Router, ParamMap, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-vendor-order-detail",
  templateUrl: "./vendor-order-detail.component.html",
  styleUrls: ["./vendor-order-detail.component.css"]
})
export class VendorOrderDetailComponent implements OnInit {
  statusForm: FormGroup;
  updateRescheduleStatusForm: FormGroup;
  assignDriverForm: FormGroup;
  pick_up_detail: any = [];
  drop_off_detail: any = [];
  customer_data: any = [];
  vendor_data: any = [];
  assignRequest = {};
  drivers = [];
  order_details: any = [];
  order_items: any = [];
  order_status: any = [];
  public orderParam = {};
  successMessage = "";
  failMessage = "";
  pageHeading = "";
  request_type = "dropoff";
  requestType = null;
  isDriver = true;
  orderId = 0;
  reschedule = [];
  showModal = true;
  public profilePath = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public api: ApiService
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    this.statusForm = this.fb.group({
      status: [""],
      order_id: [""],
      user_id: [this.myapp.loginUserkiId],
      token: [this.myapp.token]
    });

    this.updateRescheduleStatusForm = this.fb.group({
      reschedule_status: [""]
    });

    //Form Model Declaration with form validation for the fields
    this.assignDriverForm = this.fb.group({
      driver_id: ["", [Validators.required]],
      date: ["", [Validators.required]],
      order_id: [""],
      assign_type: [""]
    });

    //Access the Paramter from URLthis.route.paramMap.subscribe((params: ParamMap) => {

    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      let not_id = parseInt(params.get("not_id"));
      if (id) {
        this.getOrderDetail(id, not_id);
        this.getDrivers();
        this.pageHeading = "Order Detail";
        this.orderId = id;

        //Decrease the count
        /*if (not_id) {
          this.myapp.notificationCount--;
        }*/
      }
    });
  }

  getOrderDetail(id, not_id) {
    this.orderParam = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      vendor_id: this.myapp.vendorUserId,
      order_id: id,
      not_id: not_id
    };

    this.api
      .callAPI(this.orderParam, "vendor/orderDetail")
      .subscribe(response => {
        this.spinner.hide();
        if (response.status === true) {
          this.pick_up_detail = response.data.pick_up_detail;
          this.drop_off_detail = response.data.drop_off_detail;
          this.order_status = response.data.order_status_data;
          this.customer_data = response.data.customer_data;
          this.vendor_data = response.data.vendor_data;
          this.order_details = response.data.order_details;
          this.order_items = response.data.order_items;
          this.reschedule = response.data.reschedule;

          if (this.order_details["status"] == "1") {
            this.request_type = "pickup";
          } else {
            this.request_type = "dropoff";
          }

          console.log(response.data);

          //console.log("order data:", this.order_details);
          this.statusForm.patchValue({
            order_id: this.orderId,
            status: this.order_details["status"]
          });
          //console.log("order status:", this.order_details["status"]);
          //this.successMessage = response.message;
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          //console.log(response.message);
        }
      });
  }

  assignDriverAdmin() {
    this.isDriver = false;
  }

  assignDriverAdminCancel() {
    this.isDriver = true;
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.api.convertToDateTime(data, option);
  }

  changeOrderStatus() {
    let conf = confirm("Do you want to change order status?");
    if (conf) {
      this.myapp.hideAlerts();
      this.myapp.spinner.show();
      this.api
        .callAPI(this.statusForm.value, "vendor/changeOrderStatus")
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }
  // Function to get all drivers
  getDrivers() {
    this.api
      .callAPI(this.myapp.loginUser, "vendor/getAllSelfDrivers")
      .subscribe(response => {
        if (response.status === true) {
          this.drivers = response.data;
          console.log(this.drivers);
          // this.myapp.showSuccessAlert = true;
          // this.myapp.successMsg = response.message;
        }
      });
  }

  assignDriver(order_id, request) {
    this.assignDriverForm.patchValue({
      order_id: order_id,
      assign_type: request
    });
  }

  //form submit function
  onFormSubmit() {
    this.myapp.hideAlerts();
    let assign_driver_id = this.assignDriverForm.get("driver_id").value;
    if (assign_driver_id != "") {
      this.myapp.spinner.show();
      const formData = new FormData();
      formData.append(
        "driver_id",
        this.assignDriverForm.get("driver_id").value
      );
      formData.append("date", this.assignDriverForm.get("date").value);
      formData.append("vendor_id", this.myapp.vendorUserId);
      formData.append("order_id", this.assignDriverForm.get("order_id").value);
      formData.append(
        "assign_type",
        this.assignDriverForm.get("assign_type").value
      );
      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);

      // console.log(formData);
      this.api.callAPI(formData, "vendor/assignDriver").subscribe(response => {
        if (response.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.resetForm();
          //this.loadUserProfile(this.driver_user_id);

          // Hide the Modal
          this.showModal = false;
          let closeModal: HTMLElement = document.getElementsByClassName(
            "body"
          )[0] as HTMLElement;
          closeModal.classList.remove("modal-open");
          let closeModalpadding: HTMLElement = document.getElementsByClassName(
            "body"
          )[0] as HTMLElement;
          closeModalpadding.style.padding = "0px";
          let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
            "modal-backdrop"
          )[0] as HTMLElement;
          ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);

          this.ngOnInit();
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
    } else {
      this.assignDriverRequest(
        this.orderId,
        this.request_type,
        this.assignDriverForm.get("date").value
      );
    }
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.assignDriverForm.reset();
    //Patch the required data in Form Modal
    this.assignDriverForm.patchValue({
      driver_id: "",
      date: "",
      order_id: ""
    });
    //Patch the required data in Form Modal
    this.updateRescheduleStatusForm.patchValue({
      reschedule_status: ""
    });
  }

  //Getor - To access the fields directly in the form in HTML
  get driver_id() {
    return this.assignDriverForm.get("driver_id");
  }
  get order_id() {
    return this.assignDriverForm.get("order_id");
  }
  get date() {
    return this.assignDriverForm.get("date");
  }

  assignDriverRequest(orderId, request_type, date) {
    this.myapp.hideAlerts();
    this.assignRequest = {
      user_id: this.myapp.loginUserkiId,
      vendor_id: this.myapp.vendorUserId,
      token: this.myapp.token,
      order_id: orderId,
      request_type: request_type,
      date: date
    };
    this.api
      .callAPI(this.assignRequest, "vendor/RequestAssignDrivers")
      .subscribe(response => {
        if (response.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;

          this.resetForm();
          //this.loadUserProfile(this.driver_user_id);

          // Hide the Modal
          this.showModal = false;
          let closeModal: HTMLElement = document.getElementsByClassName(
            "body"
          )[0] as HTMLElement;
          closeModal.classList.remove("modal-open");
          let closeModalpadding: HTMLElement = document.getElementsByClassName(
            "body"
          )[0] as HTMLElement;
          closeModalpadding.style.padding = "0px";
          let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
            "modal-backdrop"
          )[0] as HTMLElement;
          ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);

          this.ngOnInit();
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
  }

  onRescheduleStatusSubmit(schedule_id) {
    this.myapp.hideAlerts();
    let conf = confirm("Do you want to update this reschedule request");
    if (conf) {
      this.myapp.spinner.show();
      const formData = new FormData();
      formData.append("schedule_id", schedule_id);
      formData.append(
        "status",
        this.updateRescheduleStatusForm.get("reschedule_status").value
      );
      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);

      this.api
        .callAPI(formData, "vendor/updateReschStatus")
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.ngOnInit();
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }

  confirmDropoffOrder(orderId) {
    this.myapp.hideAlerts();
    let conf = confirm("Do you want to confirm dropoff?");
    if (conf) {
      this.assignRequest = {
        user_id: this.myapp.loginUserkiId,
        vendor_id: this.myapp.vendorUserId,
        token: this.myapp.token,
        order_id: orderId
      };
      this.api
        .callAPI(this.assignRequest, "vendor/confirmDropoffs")
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.ngOnInit();
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }

  confirmPickupOrder(orderId) {
    this.myapp.hideAlerts();
    let conf = confirm("Do you want to confirm pickup?");
    if (conf) {
      this.assignRequest = {
        user_id: this.myapp.loginUserkiId,
        vendor_id: this.myapp.vendorUserId,
        token: this.myapp.token,
        order_id: orderId
      };
      this.api
        .callAPI(this.assignRequest, "vendor/confirmPickups")
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.ngOnInit();
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }

  orderProcessDeliver(orderId, status) {
    this.myapp.hideAlerts();
    let msg = "";
    if (status == 4) msg = "Do you want to move the status to in process?";
    else msg = "Is the order ready for delivery?";

    let conf = confirm(msg);
    if (conf) {
      this.assignRequest = {
        user_id: this.myapp.loginUserkiId,
        token: this.myapp.token,
        order_id: orderId,
        status: status
      };
      this.api
        .callAPI(this.assignRequest, "vendor/orderProcessDeliver")
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.ngOnInit();
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }
}
