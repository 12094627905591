import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-manage-reports",
  templateUrl: "./manage-reports.component.html",
  styleUrls: ["./manage-reports.component.css"]
})
export class ManageReportsComponent implements OnInit {
  public orders: any = [];
  successMessage = "";
  failMessage = "";
  excelFilePath = null;
  page = 1; //used for load more
  hideLoadMore = true;
  filterForm: FormGroup;
  public pageHeading = "";
  public ordersCount = "";
  public profilePath = null;
  order = {};
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public myapp: AppComponent,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.pageHeading = "Report Management";
    this.filterForm = this.fb.group({
      start_date: [""],
      end_date: [""]
    });
    this.reportManagement("", "");
  }

  reportManagement(start_date, end_date) {
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);

    this.api.callAPI(formData, "admin/reportManagement").subscribe(response => {
      if (response.status === true) {
        this.orders = response.data;
        this.excelFilePath = response.fileurl;
        console.log(this.orders);
      } else {
        this.orders = [];
        //this.myapp.showErrorAlert = true;
        //this.myapp.errorMsg = response.message;
      }
    });
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    console.log(pageNo);
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("start_date", this.filterForm.get("start_date").value);
    formData.append("end_date", this.filterForm.get("end_date").value);
    formData.append("page", pageNo);

    this.api
      .callAPI(formData, "admin/reportManagement")
      .subscribe((response: any) => {
        if (response.status == true) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;
          //console.log(response.data.length);
          for (let i = 0; i < response.data.length; i++) {
            this.orders.push(response.data[i]);
          }
          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
          this.hideLoadMore = false;
        }
      });
  }

  onFilterSubmit() {
    this.hideLoadMore = true;
    this.page = 1;

    let start_date = this.filterForm.get("start_date").value;
    let end_date = this.filterForm.get("end_date").value;
    this.reportManagement(start_date, end_date);
  }
}
