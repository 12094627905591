import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/service/api.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-view-customers",
  templateUrl: "./view-customers.component.html",
  styleUrls: ["./view-customers.component.css"]
})
export class ViewCustomersComponent implements OnInit {
  customer = [];
  inactive_customer = [];
  total_customer = [];
  successMessage = "";
  failMessage = "";
  page = 1; //used for load more
  hideLoadMore = true;
  public profilePath = null;
  user = {};
  filterForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private api: ApiService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""]
    });

    this.getAllCustomers("");
  }

  getAllCustomers(keyword) {
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", keyword);

    this.api.callAPI(formData, "admin/getCustomers").subscribe(response => {
      if (response.status === true) {
        this.total_customer = response.data.total_customer;
        this.inactive_customer = response.data.inactive_customer;
        this.customer = response.data.all_customers;
        //console.log(this.customers);
      } else {
        this.customer = [];
        this.total_customer = [];
        this.inactive_customer = [];
        //this.myapp.showErrorAlert = true;
        //this.myapp.errorMsg = response.message;
      }
    });
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    //console.log(pageNo);
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", this.filterForm.get("keyword").value);
    formData.append("page", pageNo);

    this.api
      .callAPI(formData, "admin/getCustomers")
      .subscribe((response: any) => {
        if (response.status == true) {
          this.myapp.spinner.hide();
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;
          console.log(response.data.all_customers.length);
          for (let i = 0; i < response.data.all_customers.length; i++) {
            this.customer.push(response.data.all_customers[i]);
          }
          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.all_customers.length)
            this.hideLoadMore = false;
        } else {
          this.myapp.spinner.hide();
          this.myapp.isLoadingResults = false;
          this.hideLoadMore = false;
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      });
  }

  //On Keyword filter
  filterData() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    this.getAllCustomers(keyword);
  }
}
