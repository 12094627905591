import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"]
})
export class ChangePasswordComponent implements OnInit {
  public pageHeading = "Change Password";
  FileErrorMessageLog = false;
  changePasswordForm: FormGroup;
  addDriver = true;
  type = "password";
  user = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService,
    public spinner: NgxSpinnerService
  ) {}
  ngOnInit() {
    // custom validator to check that two fields match
    // function MustMatch(controlName: string, matchingControlName: string) {
    //   return (formGroup: FormGroup) => {
    //     const control = formGroup.controls[controlName];
    //     const matchingControl = formGroup.controls[matchingControlName];

    //     if (matchingControl.errors && !matchingControl.errors.mustMatch) {
    //       // return if another validator has already found an error on the matchingControl
    //       return;
    //     }
    //     // set error on matchingControl if validation fails
    //     if (control.value !== matchingControl.value) {
    //       matchingControl.setErrors({ mustMatch: true });
    //     } else {
    //       matchingControl.setErrors(null);
    //     }
    //   };
    // }

    this.myapp.successMsg = "";
    this.myapp.errorMsg = "";
    /*this.myapp.spinner.show();
    setTimeout(() => {
      this.myapp.spinner.hide();
    }, 2000);*/

    //Form Model Declaration with form validation for the fields
    this.changePasswordForm = this.fb.group(
      {
        old_pass: ["", [Validators.required]],
        new_pass: [
          "",
          [
            Validators.required,
            Validators.pattern(
              "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
            )
          ]
        ],
        conf_pass: ["", [Validators.required]]
      }
      // { validator: MustMatch("newPass", "confPass") }
    );
  }

  //form submit function
  onFormSubmit() {
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("new_pass", this.changePasswordForm.get("new_pass").value);
    formData.append("old_pass", this.changePasswordForm.get("old_pass").value);
    formData.append(
      "conf_pass",
      this.changePasswordForm.get("conf_pass").value
    );
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);

    //console.log("Service Image:" + this.service_image.value);
    this.api.callAPI(formData, "admin/changePassword").subscribe(response => {
      if (response.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        //this.router.navigate(["../change-password"]);
        this.resetForm();
      } else {
        this.myapp.spinner.hide();
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.changePasswordForm.reset();
    //Patch the required data in Form Modal
    this.changePasswordForm.patchValue({
      new_pass: "",
      old_pass: "",
      conf_pass: ""
    });
    this.type = "password";
  }

  showhide(input) {
    this.type = input;
  }

  //Getor - To access the fields directly in the form in HTML
  get new_pass() {
    return this.changePasswordForm.get("new_pass");
  }
  get old_pass() {
    return this.changePasswordForm.get("old_pass");
  }
  get conf_pass() {
    return this.changePasswordForm.get("conf_pass");
  }
}
