import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { MapsAPILoader, MouseEvent } from "@agm/core";
import { AppComponent } from "src/app/app.component";
import { ApiService } from "src/app/service/api.service";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-add-vendor",
  templateUrl: "./add-vendor.component.html",
  styleUrls: ["./add-vendor.component.css"]
})
export class AddVendorComponent implements OnInit {
  public vendorForm: FormGroup;
  public pageHeading = "Add Vendor";
  imgPreviewLogoPath = null;
  imgPreviewBannerPath = null;
  user = {};
  FileErrorLogoMessageLog = false;
  FileErrorBannerMessageLog = false;
  successMessage = "";
  failMessage = "";
  addVendor = true;
  isLogoSelected = false;
  chooseFileLabel = "Choose a file...";

  isMapShow: boolean = false;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  imageChangedEvent: any = "";
  imageSave = false;
  imageHide = false;

  @ViewChild("search", { static: false })
  public searchElementRef: ElementRef;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadUserData(id);
        this.pageHeading = "Edit Vendor";
        this.addVendor = false;
      }
    });

    this.myapp.successMsg = "";
    this.myapp.errorMsg = "";
    /*this.myapp.spinner.show();
    setTimeout(() => {
      this.myapp.spinner.hide();
    }, 2000);*/

    //Form Model Declaration with form validation for the fields
    this.vendorForm = this.fb.group({
      vendor_user_id: [""],
      store_name: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z ()]+$"),
          Validators.minLength(3),
          Validators.maxLength(50)
        ]
      ],
      store_mobile_number: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(10),
          Validators.pattern("^[0-9()]+$")
        ]
      ],
      store_address: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(500)
        ]
      ],
      lat: ["", [Validators.required]],
      long: ["", [Validators.required]],
      store_email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.minLength(5),
          Validators.maxLength(100)
        ]
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
          )
        ]
      ],
      pin_code: [
        "",
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(6),
          Validators.pattern("^[0-9()]+$")
        ]
      ],
      service_area_distance: [
        "",
        [Validators.required, Validators.pattern("^[0-9()]+$")]
      ],
      owner_name: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.pattern("^[a-zA-Z ()]+$")
        ]
      ],
      country_code: ["", [Validators.required]],
      owner_number: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(10),
          Validators.pattern("^[0-9()]+$")
        ]
      ],
      kyc_id_title: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.pattern("^[a-zA-Z ()]+$")
        ]
      ],
      kyc_id: ["", [Validators.required]],
      owner_email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.minLength(5),
          Validators.maxLength(100)
        ]
      ],
      bank_name: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
          Validators.pattern("^[a-zA-Z ()]+$")
        ]
      ],
      account_holder_name: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.pattern(/^[a-zA-Z\s]*$/)
        ]
      ],
      account_no: [
        "",
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(18),
          Validators.pattern("^[A-Za-z0-9]+$")
        ]
      ],
      business_logo: [""],
      business_banner: [""]
    });

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        //this.searchElementRef.nativeElement
        this.searchElementRef.nativeElement,
        {
          types: ["address"]
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          this.isMapShow = true;
          console.log("Map is showing");
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 8;
          this.address = place.formatted_address;

          this.vendorForm.controls["lat"].patchValue(this.latitude);
          this.vendorForm.controls["long"].patchValue(this.longitude);
          this.vendorForm.controls["store_address"].patchValue(this.address);
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        //this.latitude = position.coords.latitude;
        //this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: parseFloat(latitude), lng: parseFloat(longitude) } },
      (results, status) => {
        console.log(results);
        console.log(status);
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 8;
            this.address = results[0].formatted_address;

            this.vendorForm.controls["lat"].patchValue(latitude);
            this.vendorForm.controls["long"].patchValue(longitude);
            this.vendorForm.controls["store_address"].patchValue(this.address);
          } else {
            //window.alert("No results found");
          }
        } else {
          //window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  //load data in edit form
  loadUserData(id) {
    this.user = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      vendor_user_id: id
    };
    this.myapp.spinner.show();
    this.api
      .callAPI(this.user, "admin/getVendorProfile")
      .subscribe(response => {
        if (response.status === true) {
          this.myapp.spinner.hide();

          if (response.data.vendor_data.business_banner)
            this.isLogoSelected = true;

          //Patch the Item data in menuForm
          this.vendorForm.patchValue({
            vendor_user_id: id,
            store_name: response.data.vendor_data.business_name,
            store_mobile_number:
              response.data.vendor_data.business_mobile_number,
            store_address: response.data.vendor_data.address,
            lat: response.data.vendor_data.latitude,
            long: response.data.vendor_data.longitude,
            store_email: response.data.vendor_data.business_email,
            pin_code: response.data.vendor_data.pin_code,
            service_area_distance:
              response.data.vendor_data.service_area_distance,
            owner_name:
              response.data.vendor_data.first_name +
              " " +
              response.data.vendor_data.last_name,
            country_code: response.data.vendor_data.country_code,
            owner_number: response.data.vendor_data.mobile_number,
            owner_email: response.data.vendor_data.email,
            kyc_id_title: response.data.vendor_data.kyc_id_title,
            kyc_id: response.data.vendor_data.kyc_id,
            bank_name: response.data.vendor_data.bank_name,
            account_holder_name: response.data.vendor_data.account_holder_name,
            account_no: response.data.vendor_data.account_no,
            business_logo: response.data.vendor_data.business_logo,
            business_banner: response.data.vendor_data.business_banner
          });

          if (response.data.vendor_data.business_logo) {
            this.imgPreviewLogoPath = response.data.vendor_data.business_logo;
          }

          if (response.data.vendor_data.business_banner) {
            this.imgPreviewBannerPath =
              response.data.vendor_data.business_banner;
          }
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
  }

  //on File select, put the selected file in Form model variable
  onFileSelect(event: any, type: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      //console.log("files" + file);
      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      if (fileType === "image") {
        if (type == "logo") {
          this.isLogoSelected = true;
          this.vendorForm.get("business_logo").setValue(file);
          this.FileErrorLogoMessageLog = false;
          //Preveiw of the image only on images
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = _event => {
            this.imgPreviewLogoPath = reader.result;
          };
        } else if (type == "banner") {
          this.imageHide = true;
          this.imageSave = true;

          //this.serviceForm.get("service_image").setValue(file);
          this.vendorForm.get("business_banner").setValue(file);
          this.imageChangedEvent = event;
          this.FileErrorBannerMessageLog = false;
          //Preveiw of the image only on images
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = _event => {
            this.imgPreviewBannerPath = reader.result;
          };
        }
        return;
      } else {
        if (type == "logo") {
          this.FileErrorLogoMessageLog = true;
        } else if (type == "banner") {
          this.FileErrorBannerMessageLog = true;
        }
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log("Cropping: " + JSON.stringify(event));
    this.imgPreviewBannerPath = event.base64;
    this.vendorForm.get("business_banner").setValue(event.base64);
  }

  saveCropImg() {
    this.imageSave = false;
  }

  //form submit function
  onFormSubmit() {
    // console.log("Banner Image:" + this.business_banner.value);
    this.myapp.spinner.show();
    const formData = new FormData();
    console.log(formData);
    formData.append(
      "vendor_user_id",
      this.vendorForm.get("vendor_user_id").value
    );
    formData.append("store_name", this.vendorForm.get("store_name").value);
    formData.append(
      "store_mobile_number",
      this.vendorForm.get("store_mobile_number").value
    );
    formData.append(
      "store_address",
      this.vendorForm.get("store_address").value
    );
    formData.append("latitude", this.vendorForm.get("lat").value);
    formData.append("longitude", this.vendorForm.get("long").value);
    formData.append("store_email", this.vendorForm.get("store_email").value);
    formData.append("password", this.vendorForm.get("password").value);
    formData.append("pin_code", this.vendorForm.get("pin_code").value);
    formData.append(
      "service_area_distance",
      this.vendorForm.get("service_area_distance").value
    );
    formData.append("owner_name", this.vendorForm.get("owner_name").value);
    formData.append("country_code", this.vendorForm.get("country_code").value);
    formData.append("owner_number", this.vendorForm.get("owner_number").value);
    formData.append("owner_email", this.vendorForm.get("owner_email").value);
    formData.append("kyc_id_title", this.vendorForm.get("kyc_id_title").value);
    formData.append("kyc_id", this.vendorForm.get("kyc_id").value);
    formData.append("bank_name", this.vendorForm.get("bank_name").value);
    formData.append(
      "account_holder_name",
      this.vendorForm.get("account_holder_name").value
    );
    formData.append("account_no", this.vendorForm.get("account_no").value);
    formData.append(
      "business_logo",
      this.vendorForm.get("business_logo").value
    );
    formData.append(
      "business_banner",
      this.vendorForm.get("business_banner").value
    );
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);

    //console.log("Service Image:" + this.service_image.value);
    this.api.callAPI(formData, "admin/addVendor").subscribe(response => {
      if (response.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.isMapShow = false;
        this.router.navigate(["../admin/view-vendors"]);
      } else {
        this.myapp.spinner.hide();
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.vendorForm.reset();
    //Patch the required data in Form Modal
    this.vendorForm.patchValue({
      vendor_user_id: "",
      store_name: "",
      store_mobile_number: "",
      store_address: "",
      store_email: "",
      password: "",
      pin_code: "",
      service_area_distance: "",
      owner_name: "",
      country_code: "",
      owner_number: "",
      owner_email: "",
      bank_name: "",
      account_holder_name: "",
      account_no: "",
      business_logo: "",
      business_banner: "",
      latitude: "",
      longitude: "",
      kyc_id_title: "",
      kyc_id: ""
    });
    this.isLogoSelected = false;
  }

  //Getor - To access the fields directly in the form in HTML
  get store_name() {
    return this.vendorForm.get("store_name");
  }
  get store_mobile_number() {
    return this.vendorForm.get("store_mobile_number");
  }
  get store_address() {
    return this.vendorForm.get("store_address");
  }
  get lat() {
    return this.vendorForm.get("lat");
  }
  get long() {
    return this.vendorForm.get("long");
  }
  get store_email() {
    return this.vendorForm.get("store_email");
  }
  get password() {
    return this.vendorForm.get("password");
  }
  get pin_code() {
    return this.vendorForm.get("pin_code");
  }
  get service_area_distance() {
    return this.vendorForm.get("service_area_distance");
  }
  get owner_name() {
    return this.vendorForm.get("owner_name");
  }
  get country_code() {
    return this.vendorForm.get("country_code");
  }
  get owner_number() {
    return this.vendorForm.get("owner_number");
  }
  get owner_email() {
    return this.vendorForm.get("owner_email");
  }
  get bank_name() {
    return this.vendorForm.get("bank_name");
  }
  get account_holder_name() {
    return this.vendorForm.get("account_holder_name");
  }
  get account_no() {
    return this.vendorForm.get("account_no");
  }
  get business_logo() {
    return this.vendorForm.get("business_logo");
  }
  get business_banner() {
    return this.vendorForm.get("business_banner");
  }
  get kyc_id_title() {
    return this.vendorForm.get("kyc_id_title");
  }
  get kyc_id() {
    return this.vendorForm.get("kyc_id");
  }

  goBack() {
    history.back();
    return false;
  }
}
