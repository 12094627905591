import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/service/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-customer-profile",
  templateUrl: "./customer-profile.component.html",
  styleUrls: ["./customer-profile.component.css"]
})
export class CustomerProfileComponent implements OnInit {
  public filterOrderForm: FormGroup;
  public filterTransForm: FormGroup;
  pageHeading = "";
  user = {};
  userChangeStatus = {};
  customer: any = [];
  customer_info: any = [];
  failMessage = "";
  successMessage = "";
  suspendUser = false;
  suspendReason = "";
  customerUserId: any = 0;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public api: ApiService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Form Model Declaration with form validation for the fields
    this.filterOrderForm = this.fb.group({
      keyword: [""]
    });

    this.filterTransForm = this.fb.group({
      keyword: [""]
    });

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadUserProfile(id);
        this.pageHeading = "Customer Profile";
        this.customerUserId = id;
      }
    });
  }

  loadUserProfile(id) {
    this.user = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      customer_user_id: id
    };
    this.api
      .callAPI(this.user, "admin/getCustomerProfile")
      .subscribe(response => {
        if (response.status === true) {
          this.customer = response.data;
          this.customer_info = response.data.customer_data;
          //console.log(this.customer_info);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          //console.log(response.message);
        }
      });
  }

  showSuspendBox() {
    this.suspendUser = true;
  }

  cancelSuspend() {
    this.suspendUser = false;
    this.suspendReason = "";
  }

  changeStatus(id, value) {
    this.userChangeStatus = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      status: value,
      customer_user_id: id,
      reason: this.suspendReason
    };
    console.log(this.userChangeStatus);
    this.spinner.show();
    this.api
      .callAPI(this.userChangeStatus, "admin/customerChangeStatus")
      .subscribe(response => {
        this.spinner.hide();
        if (response.status === true) {
          this.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;

          this.customer_info["status"] = value;
          this.suspendUser = false;
          if (value == 2)
            this.customer_info["suspend_reason"] = this.suspendReason;
          else {
            this.customer_info["suspend_reason"] = null;
            this.suspendReason = "";
          }
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
  }

  //Order sOn Keyword filter
  filterOrderData() {
    this.myapp.showErrorAlert = false;
    this.myapp.errorMsg = "";
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", this.filterOrderForm.get("keyword").value);
    formData.append("customer_user_id", this.customerUserId);
    formData.append("type", "order");

    this.api
      .callAPI(formData, "admin/getFilterCustomerData")
      .subscribe(response => {
        if (response.status === true) {
          //this.vendor_order_data = response.data.vendor_order_data;
          this.customer.customer_order_data = response.data;
        } else {
          this.customer.customer_order_data = [];
        }
      });
  }

  //Transaction On Keyword filter
  filterTransData() {
    this.myapp.showErrorAlert = false;
    this.myapp.errorMsg = "";
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", this.filterTransForm.get("keyword").value);
    formData.append("customer_user_id", this.customerUserId);
    formData.append("type", "trans");

    this.api
      .callAPI(formData, "admin/getFilterCustomerData")
      .subscribe(response => {
        if (response.status === true) {
          //this.vendor_order_data = response.data.vendor_order_data;
          this.customer.customer_transactions = response.data;
        } else {
          this.customer.customer_transactions = [];
        }
      });
  }

  //Transactions filter
  /*filterTransData(vendorId) {
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", this.filterTransForm.get("keyword").value);
    formData.append("vendor_user_id", vendorId);

    this.api
      .callAPI(formData, "admin/getFilterVendorPayments")
      .subscribe(response => {
        if (response.status === true) {
          this.vendor_transactions = response.data;
          //console.log(response.data);
        } else {
          this.vendor_transactions = [];
        }
      });
  }*/

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.api.convertToDateTime(data, option);
  }
}
