import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "./service/api.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  isUserLogin = false;
  isLoadingResults = false;
  showSuccessAlert = false;
  showErrorAlert = false;
  successMsg = "";
  vendorUserId = null;
  errorMsg = "";
  drivers = [];
  loginUserkiId = null;
  roleId = null;
  token = null;
  loginVendorUser = {};
  loginUser = {};
  notificationCount: number = 0;

  constructor(
    private router: Router,
    private api: ApiService,
    public spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    /** spinner starts on init */
    /*this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);*/

    //Hide the notifiation after 10 sec
    setInterval(() => {
      this.hideAlerts();
    }, 10000);

    //Login Access Validation
    this.isUserLogin = this.api.isUserLogin();

    //Redirect to Dashboard if user comes to login or base URL after successful login
    /*if (this.isUserLogin) {
      this.router.navigate(["/dashboard"]);
    }*/

    // If user try to reload the page,
    if (this.isUserLogin) {
      this.loginUserkiId = localStorage.getItem("loginUserKiId");
      this.roleId = localStorage.getItem("role_id");
      this.token = localStorage.getItem("token");
      this.vendorUserId = localStorage.getItem("vendor_id");

      this.loginUser = {
        user_id: this.loginUserkiId,
        token: this.token
      };
      this.loginVendorUser = {
        user_id: this.loginUserkiId,
        vendor_id: this.vendorUserId,
        token: this.token
      };

      /*setInterval(() => {
        if (this.loginUserkiId == null) window.location.href = "./login";
      }, 1000);*/

      if (this.roleId == 1) {
        //this.router.navigate(["/admin/admin-dashboard"]);
      } else if (this.roleId == 2) {
        //this.router.navigate(["/vendor/dashboard"]);
      }
    }

    // Restrict other login on another tab
    if (window.localStorage.getItem("isUserLogin")) {
      console.log("LoggedIn");
      setInterval(() => {
        //console.log("LogedIn");
        this.loginUserkiId = window.localStorage.getItem("loginUserKiId");
        if (this.loginUserkiId == null) window.location.href = "./login";
      }, 1000);
    }
  }

  //Function to hide Alerts on before each event calling
  hideAlerts() {
    this.showSuccessAlert = false;
    this.showErrorAlert = false;
    this.successMsg = "";
    this.errorMsg = "";
  }

  // Function to logout user
  /*getDrivers() {
    this.api
      .callAPI(this.loginUser, "admin/getAllDrivers")
      .subscribe((response) => {
        if (response.status === true) {
          this.drivers = response.data;
          console.log(this.drivers);
          this.showSuccessAlert = true;
          this.successMsg = response.message;
        }
      });
  }*/

  // Function to logout user
  logout() {
    //Destory the local storage
    localStorage.clear();
    window.location.href = "./login";
    /*const postData = new FormData();
    postData.append("token", localStorage.getItem("token"));
    if (localStorage.getItem("userType") == "B") {
      postData.append("barId", localStorage.getItem("barId"));
    } else {
      postData.append("userId", localStorage.getItem("userId"));
    }

    this.bs.callAPI(postData, "barLogout").subscribe(response => {
      if (response.status === 1) {
        console.log(response);
        //Destory the local storage
        localStorage.clear();
        window.location.href = "./login";
      } else {
        this.showErrorAlert = true;
        this.errorMsg = response.message;
        this.isLoadingResults = false;
      }
    }); */
    return false;
  }
}
