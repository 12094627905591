import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl
} from "@angular/forms";
import { Router, ParamMap, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-edit-order",
  templateUrl: "./edit-order.component.html",
  styleUrls: ["./edit-order.component.css"]
})
export class EditOrderComponent implements OnInit {
  public orderForm: FormGroup;
  order_id = 0;
  service_box = [];
  service_pieces = [];
  orderCost: any = 0;
  orderItems = [];
  orderSelectedItems = [];
  public group = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public api: ApiService
  ) {}

  ngOnInit() {
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.order_id = id;
        this.getOrderItems(id);
        this.group["order_id"] = new FormControl(id);
      }
    });

    //this.loadServices();
    this.group["user_id"] = new FormControl(this.myapp.loginUserkiId);
    this.group["token"] = new FormControl(this.myapp.token);
    this.orderForm = this.fb.group(this.group);
  }

  // Function get package details
  getOrderItems(id) {
    let formData = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      order_id: id
    };
    this.api.callAPI(formData, "admin/getOrderItems").subscribe(response => {
      if (response.status === true) {
        this.orderCost = response.data.total_amount;
        //console.log("response:", response.data);
        this.orderItems = response.data.services;

        this.orderItems.forEach(data => {
          data.items.forEach(it => {
            //this.group["box_item_id_" + it.item_id] = new FormControl("");

            /*if (it.unit_type == "box") {
              this.orderForm.controls[
                "box_item_id_" + it.service_item_id
              ].patchValue(it.quantity);
            } else {
              this.orderForm.controls[
                "item_id_" + it.service_item_id
              ].patchValue(it.quantity);
            }*/

            this.group["item_id_" + it.item_id] = new FormControl(it.quantity);
            this.orderForm = this.fb.group(this.group);

            const item = {
              item_id: it.item_id,
              price: it.unit_price,
              qty: it.quantity
            };
            this.orderSelectedItems.push(item);
          });
        });
      }
    });
  }

  /*loadServices() {
    this.myapp.spinner.show();
    let formData = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token
    };
    this.api.callAPI(formData, "admin/getAllServices").subscribe(response => {
      if (response.status === true) {
        this.service_box = response.data.box_items;
        this.service_pieces = response.data.piece_items;

        // Boxes
        this.service_box.forEach(data => {
          //console.log("Service DAta", data);
          data.items.forEach(it => {
            //console.log("Item ID:" + "item_id_" + it.item_id);
            this.group["item_id_" + it.item_id] = new FormControl("");
          });
        });

        // Piece Items
        this.service_pieces.forEach(data => {
          data.items.forEach(it => {
            this.group["item_id_" + it.item_id] = new FormControl("");
          });
        });
        //console.log("box items", this.service_box);

        this.orderForm = this.fb.group(this.group);
        this.myapp.spinner.hide();
      }
    });
  }*/

  //form submit function
  onFormSubmit() {
    this.myapp.hideAlerts();
    this.myapp.spinner.show();
    this.api
      .callAPI(this.orderForm.value, "admin/editOrderItems") //this.packageForm.value
      .subscribe(response => {
        if (response.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.router.navigate(["../admin/order-detail/" + this.order_id]);
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
  }

  calculateItemPrice(sIndex, iIndex, qty, unit_price, event) {
    let value = event.target.value;
    //console.log(event.target.value);
    if (value.length >= 4) {
      //event.preventDefault();
      let number = value.substring(0, 4);
      event.target.value = number;
      qty = number;
    }

    if (!Number(qty)) {
      qty = 0;
    }
    let total = qty * unit_price;
    //console.log("total:" + total);
    this.orderItems[sIndex].items[iIndex].total_price = total;
    this.calculateOrderTotal();
  }

  calculateOrderTotal() {
    let total = 0;
    this.orderItems.forEach(data => {
      data.items.forEach(it => {
        this.group["item_id_" + it.item_id] = new FormControl("");
        total = total + it.total_price;
      });
    });
    this.orderCost = total.toFixed(2);
  }

  goBack() {
    history.back();
    return false;
  }
}
