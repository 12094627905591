import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-vendor-view-drivers",
  templateUrl: "./vendor-view-drivers.component.html",
  styleUrls: ["./vendor-view-drivers.component.css"],
})
export class VendorViewDriversComponent implements OnInit {
  public drivers: any = [];
  filterForm: FormGroup;
  successMessage = "";
  failMessage = "";
  pageHeading = "";
  counts: any = [];
  public profilePath = null;
  user = {};
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private spinner: NgxSpinnerService,
    private api: ApiService
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
    });

    this.getAllDrivers("");
    this.pageHeading = "Driver Management";
  }

  getAllDrivers(keyword) {
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", keyword);
    formData.append("vendor_id", this.myapp.vendorUserId);

    this.api.callAPI(formData, "vendor/getDrivers").subscribe((response) => {
      if (response.status === true) {
        this.counts = response.data;
        this.drivers = response.data.all_drivers;
        //console.log(this.drivers);
        // this.successMessage = response.message;
      } else {
        this.drivers = [];
        this.counts = [];
        //this.myapp.showErrorAlert = true;
        //this.myapp.errorMsg = response.message;
      }
    });
  }

  //On Keyword filter
  filterData() {
    let keyword = this.filterForm.get("keyword").value;
    this.getAllDrivers(keyword);
  }
}
