import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-verify-email-otp",
  templateUrl: "./verify-email-otp.component.html",
  styleUrls: ["./verify-email-otp.component.css"]
})
export class VerifyEmailOtpComponent implements OnInit {
  verifyEmailOtp: FormGroup;
  successMessage = "";
  failMessage = "";
  pageHeading = "";
  value = null;
  emailotp = null;
  user_verification_id = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public myapp: AppComponent,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.value = localStorage.getItem("value");
    this.emailotp = localStorage.getItem("otp");

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.user_verification_id = id;
      }
    });
    this.pageHeading = "Change Email";
    //Form Model Declaration with form validation for the fields
    this.verifyEmailOtp = this.fb.group({
      otp: [
        "",
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
          Validators.pattern("^[0-9()]+$")
        ]
      ]
    });
  }

  onOtpSubmit() {
    this.myapp.showSuccessAlert = false;
    this.myapp.showErrorAlert = false;
    this.myapp.successMsg = "";
    this.myapp.errorMsg = "";

    this.myapp.spinner.show();
    const formData = new FormData();
    if (this.value == 1) {
      formData.append("otp", this.verifyEmailOtp.get("otp").value);
      formData.append("user_verification_id", this.user_verification_id);
      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);

      this.api
        .callAPI(formData, "vendor/verifyChangeMyEmailOtp1")
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.router.navigate(["../vendor/change-email"]);
            console.log(response.data);
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            console.log(response.message);
          }
        });
    }
    if (this.value == 2) {
      formData.append("otp", this.verifyEmailOtp.get("otp").value);
      formData.append("user_verification_id", this.user_verification_id);
      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);
      this.api
        .callAPI(formData, "vendor/verifyChangeMyEmailOtp2")
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.router.navigate(["../vendor/settings"]);
            //console.log(response.data);
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            //console.log(response.message);
          }
        });
    }
  }

  //Getor - To access the fields directly in the form in HTML
  get otp() {
    return this.verifyEmailOtp.get("otp");
  }
}
