import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./vendor/dashboard/dashboard.component";
import { AdminDashboardComponent } from "./admin/admin-dashboard/admin-dashboard.component";
import { ViewCustomersComponent } from "./admin/customer/view-customers/view-customers.component";
import { CustomerProfileComponent } from "./admin/customer/customer-profile/customer-profile.component";
import { AddDriverComponent } from "./admin/driver/add-driver/add-driver.component";
import { DriverProfileComponent } from "./admin/driver/driver-profile/driver-profile.component";
import { ViewDriversComponent } from "./admin/driver/view-drivers/view-drivers.component";
import { AddVendorComponent } from "./admin/vendor/add-vendor/add-vendor.component";
import { VendorProfileComponent } from "./admin/vendor/vendor-profile/vendor-profile.component";
import { ViewVendorsComponent } from "./admin/vendor/view-vendors/view-vendors.component";
import { AppSettingComponent } from "./admin/setting/app-setting/app-setting.component";
import { AddPackageComponent } from "./admin/package/add-package/add-package.component";
import { EditPackageComponent } from "./admin/package/edit-package/edit-package.component";
import { PackageDetailComponent } from "./admin/package/package-detail/package-detail.component";
import { ViewPackagesComponent } from "./admin/package/view-packages/view-packages.component";
import { AddServiceComponent } from "./admin/service/add-service/add-service.component";
import { ViewServicesComponent } from "./admin/service/view-services/view-services.component";
import { ViewServiceItemsComponent } from "./admin/service/view-service-items/view-service-items.component";
import { EditServiceItemsComponent } from "./admin/service/edit-service-items/edit-service-items.component";
import { ManageBoxesComponent } from "./admin/service/manage-boxes/manage-boxes.component";
import { ViewOrdersComponent } from "./admin/order/view-orders/view-orders.component";
import { OrderDetailComponent } from "./admin/order/order-detail/order-detail.component";
import { EditOrderComponent } from "./admin/order/edit-order/edit-order.component";
import { CustomerTransactionComponent } from "./admin/transaction/customer-transaction/customer-transaction.component";
import { VendorTransactionComponent } from "./admin/transaction/vendor-transaction/vendor-transaction.component";
import { DriverTransactionComponent } from "./admin/transaction/driver-transaction/driver-transaction.component";
import { AddTransactionComponent } from "./admin/transaction/add-transaction/add-transaction.component";
import { ManageBannersComponent } from "./admin/setting/manage-banners/manage-banners.component";
import { ManageReportsComponent } from "./admin/setting/manage-reports/manage-reports.component";
import { AssignDriverComponent } from "./admin/order/assign-driver/assign-driver.component";
import { NotificationComponent } from "./admin/notification/notification.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { TransactionsComponent } from "./vendor/transactions/transactions.component";
import { VendorAddDriverComponent } from "./vendor/driver/vendor-add-driver/vendor-add-driver.component";
import { VendorDriverProfileComponent } from "./vendor/driver/vendor-driver-profile/vendor-driver-profile.component";
import { VendorViewDriversComponent } from "./vendor/driver/vendor-view-drivers/vendor-view-drivers.component";
import { VendorNotificationComponent } from "./vendor/vendor-notification/vendor-notification.component";
import { VendorSettingsComponent } from "./vendor/vendor-settings/vendor-settings.component";
import { VendorViewOrderComponent } from "./vendor/order/vendor-view-order/vendor-view-order.component";
import { VendorOrderDetailComponent } from "./vendor/order/vendor-order-detail/vendor-order-detail.component";
import { VendorEditOrderComponent } from "./vendor/order/vendor-edit-order/vendor-edit-order.component";
import { VerifyPhoneOtpComponent } from "./vendor/profile/change-phone/verify-phone-otp/verify-phone-otp.component";
import { EmailComponent } from "./vendor/profile/change-email/email/email.component";
import { PhoneComponent } from "./vendor/profile/change-phone/phone/phone.component";
import { VerifyEmailOtpComponent } from "./vendor/profile/change-email/verify-email-otp/verify-email-otp.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    data: { title: "Login" }
  },
  {
    path: "admin/admin-dashboard",
    component: AdminDashboardComponent,
    data: { title: "Admin Dashboard" }
  },
  {
    path: "admin/view-customers",
    component: ViewCustomersComponent,
    data: { title: "View Customers" }
  },
  {
    path: "admin/customer-profile/:id",
    component: CustomerProfileComponent,
    data: { title: "Customer Profile" }
  },
  {
    path: "admin/add-driver",
    component: AddDriverComponent,
    data: { title: "Add Driver" }
  },
  {
    path: "admin/driver-profile/:id",
    component: DriverProfileComponent,
    data: { title: "Driver Profile" }
  },
  {
    path: "admin/view-drivers",
    component: ViewDriversComponent,
    data: { title: "View Drivers" }
  },
  {
    path: "admin/add-driver/:id",
    component: AddDriverComponent,
    data: { title: "Edit Driver" }
  },
  {
    path: "admin/add-vendor",
    component: AddVendorComponent,
    data: { title: "Add Vendor" }
  },
  {
    path: "admin/vendor-profile/:id",
    component: VendorProfileComponent,
    data: { title: "Vendor Profile" }
  },
  {
    path: "admin/view-vendors",
    component: ViewVendorsComponent,
    data: { title: "Vendor Profile" }
  },
  {
    path: "admin/add-vendor/:id",
    component: AddVendorComponent,
    data: { title: "Edit Vendor" }
  },
  {
    path: "admin/app-settings",
    component: AppSettingComponent,
    data: { title: "App Settings" }
  },
  {
    path: "admin/manage-reports",
    component: ManageReportsComponent,
    data: { title: "Manage Reports" }
  },
  {
    path: "admin/add-package",
    component: AddPackageComponent,
    data: { title: "Add Package" }
  },
  {
    path: "admin/edit-package/:id",
    component: EditPackageComponent,
    data: { title: "Edit Package" }
  },
  {
    path: "admin/package-detail/:id",
    component: PackageDetailComponent,
    data: { title: "Package Detail" }
  },
  {
    path: "admin/view-packages",
    component: ViewPackagesComponent,
    data: { title: "View Packages" }
  },
  {
    path: "admin/add-service",
    component: AddServiceComponent,
    data: { title: "Add Service" }
  },
  {
    path: "admin/view-service-items/:id",
    component: ViewServiceItemsComponent,
    data: { title: "View Service Items" }
  },
  {
    path: "admin/edit-service-items/:id",
    component: EditServiceItemsComponent,
    data: { title: "Edit Service Items" }
  },
  {
    path: "admin/view-services",
    component: ViewServicesComponent,
    data: { title: "View Services" }
  },
  {
    path: "admin/manage-boxes",
    component: ManageBoxesComponent,
    data: { title: "Manage Boxes" }
  },
  {
    path: "admin/view-orders",
    component: ViewOrdersComponent,
    data: { title: "View Order" }
  },
  {
    path: "admin/order-detail/:id",
    component: OrderDetailComponent,
    data: { title: "Order Detail" }
  },
  {
    path: "admin/order-detail/:id/:not_id",
    component: OrderDetailComponent,
    data: { title: "Order Detail" }
  },
  {
    path: "admin/edit-order/:id",
    component: EditOrderComponent,
    data: { title: "Edit Order" }
  },
  {
    path: "admin/assign-driver",
    component: AssignDriverComponent,
    data: { title: "Assign Driver" }
  },
  {
    path: "admin/transactions",
    component: CustomerTransactionComponent,
    data: { title: "Customer Transactions" }
  },
  {
    path: "admin/driver-transactions",
    component: DriverTransactionComponent,
    data: { title: "Driver Transactions" }
  },
  {
    path: "admin/vendor-transactions",
    component: VendorTransactionComponent,
    data: { title: "Vendor Transactions" }
  },
  {
    path: "admin/add-transaction",
    component: AddTransactionComponent,
    data: { title: "Add Transaction" }
  },
  {
    path: "admin/manage-banners",
    component: ManageBannersComponent,
    data: { title: "Manage App Banners" }
  },
  {
    path: "admin/view-notifications",
    component: NotificationComponent,
    data: { title: "Notification Management" }
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
    data: { title: "Change Password" }
  },

  //vendor routes starts
  {
    path: "vendor/dashboard",
    component: DashboardComponent,
    data: { title: "Vendor Dashboard" }
  },
  {
    path: "vendor/transactions",
    component: TransactionsComponent,
    data: { title: "Transactions" }
  },
  {
    path: "vendor/view-notifications",
    component: VendorNotificationComponent,
    data: { title: "Notifications" }
  },
  {
    path: "vendor/settings",
    component: VendorSettingsComponent,
    data: { title: "Settings" }
  },
  {
    path: "vendor/add-driver",
    component: VendorAddDriverComponent,
    data: { title: "Add Driver" }
  },
  {
    path: "vendor/driver-profile/:id",
    component: VendorDriverProfileComponent,
    data: { title: "Driver Profile" }
  },
  {
    path: "vendor/view-drivers",
    component: VendorViewDriversComponent,
    data: { title: "View Drivers" }
  },
  {
    path: "vendor/add-driver/:id",
    component: VendorAddDriverComponent,
    data: { title: "Edit Driver" }
  },
  {
    path: "vendor/view-orders",
    component: VendorViewOrderComponent,
    data: { title: "View Order" }
  },
  {
    path: "vendor/order-detail/:id",
    component: VendorOrderDetailComponent,
    data: { title: "Order Detail" }
  },
  {
    path: "vendor/order-detail/:id/:not_id",
    component: VendorOrderDetailComponent,
    data: { title: "Order Detail" }
  },
  {
    path: "vendor/edit-order/:id",
    component: VendorEditOrderComponent,
    data: { title: "Edit Order" }
  },
  {
    path: "vendor/verify-phone-otp/:id",
    component: VerifyPhoneOtpComponent,
    data: { title: "Change Phone" }
  },
  {
    path: "vendor/change-phone",
    component: PhoneComponent,
    data: { title: "Change Phone" }
  },
  {
    path: "vendor/verify-email-otp/:id",
    component: VerifyEmailOtpComponent,
    data: { title: "Change Email" }
  },
  {
    path: "vendor/change-email",
    component: EmailComponent,
    data: { title: "Change Email" }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

export const routingComponents = [LoginComponent, DashboardComponent];
