import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-package-detail",
  templateUrl: "./package-detail.component.html",
  styleUrls: ["./package-detail.component.css"]
})
export class PackageDetailComponent implements OnInit {
  public packageImgPath = this.api.packageImgPath();
  public package: any = [];
  public package_service: any = [];
  pageHeading = "";
  public profilePath = null;
  userChangeStatus = {};
  user = {};
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public myapp: AppComponent,
    private spinner: NgxSpinnerService,
    private api: ApiService
  ) {}

  ngOnInit() {
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.getPackageDetail(id);
        this.pageHeading = "Package Detail";
      }
    });
  }

  getPackageDetail(id) {
    this.user = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      pack_id: id
    };
    this.api
      .callAPI(this.user, "admin/getPackageDetail")
      .subscribe(response => {
        if (response.status === true) {
          this.package = response.data.package;
          this.package_service = response.data.pack_service_items;
          // console.log(response.data);
        }
      });
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.api.convertToDateTime(data, option);
  }

  changeStatus(id, value) {
    let msg = "";
    if (value == 1) {
      msg = "Do you want activate this package?";
    } else {
      msg = "Do you want suspend this package?";
    }
    let conf = confirm(msg);
    if (conf) {
      this.userChangeStatus = {
        user_id: this.myapp.loginUserkiId,
        token: this.myapp.token,
        status: value,
        pack_id: id
      };
      console.log(this.userChangeStatus);
      this.spinner.show();
      this.api
        .callAPI(this.userChangeStatus, "admin/packageChangeStatus")
        .subscribe(response => {
          this.spinner.hide();
          if (response.status === true) {
            this.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.package["status"] = value;
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }
}
