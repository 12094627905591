import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-edit-package",
  templateUrl: "./edit-package.component.html",
  styleUrls: ["./edit-package.component.css"]
})
export class EditPackageComponent implements OnInit {
  public pageHeading = "Add Package";
  imgPreviewPath = null;
  public packageImgPath = this.api.packageImgPath();
  public package = [];
  public package_service = [];
  qty = null;
  FileErrorMessageLog = false;
  public packageForm: FormGroup;
  service_box = [];
  service_pieces = [];
  successMessage = "";
  failMessage = "";
  packageCost: number = 0;
  user = {};
  packItems = [];
  packId = 0;
  chooseFileLabel = "Choose a file...";

  public group = {};

  //TEsting VAriables
  public formTemplate = [
    {
      type: "text",
      label: "Package Title"
    },
    {
      type: "text",
      label: "Validity"
    },
    {
      type: "select",
      label: "favorite book",
      options: ["Jane Eyre", "Pride and Prejudice", "Wuthering Heights"]
    }
  ];

  //public serviceBoxItems = [];
  //public servicePeiceItems = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}
  ngOnInit() {
    this.myapp.successMsg = "";
    this.myapp.errorMsg = "";

    //Form Model Declaration with form validation for the fields
    /*this.formTemplate.forEach(input_template => {
      this.group[input_template.label] = new FormControl("");
    });*/

    this.loadServices();
    this.group["user_id"] = new FormControl(this.myapp.loginUserkiId);
    this.group["token"] = new FormControl(this.myapp.token);
    this.group["pack_id"] = new FormControl("");
    this.group["title"] = new FormControl("", [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(200)
    ]);
    this.group["description"] = new FormControl("");
    this.group["start_date"] = new FormControl("", [Validators.required]);
    this.group["end_date"] = new FormControl("", [Validators.required]);
    this.group["price"] = new FormControl("", [
      Validators.required,
      Validators.min(1),
      Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
    ]);
    this.group["discounted_price"] = new FormControl("");
    this.group["package_image"] = new FormControl("");
    this.group["validity"] = new FormControl("", [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(3),
      Validators.pattern("^[0-9()]+$")
    ]);
    this.group["discount"] = new FormControl("", [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(3),
      Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
    ]);
    this.packageForm = this.fb.group(this.group);

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        //this.getPackageDetail(id);
        this.packId = id;
      }
    });
  }

  async loadServices() {
    console.log("calling servies");
    this.myapp.spinner.show();
    this.user = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token
    };
    this.api.callAPI(this.user, "admin/getAllServices").subscribe(response => {
      if (response.status === true) {
        this.service_box = response.data.box_items;
        this.service_pieces = response.data.piece_items;

        // Boxes
        this.service_box.forEach(data => {
          //console.log("Service DAta", data);
          this.group["service_" + data.service_id] = new FormControl("");
          data.items.forEach(it => {
            //console.log("Item ID:" + "item_id_" + it.item_id);
            this.group["box_item_id_" + it.item_id] = new FormControl("");
          });
        });

        // Piece Items
        this.service_pieces.forEach(data => {
          this.group["service_" + data.service_id] = new FormControl("");
          data.items.forEach(it => {
            this.group["item_id_" + it.item_id] = new FormControl("");
          });
        });
        //console.log("box items", this.service_box);

        this.packageForm = this.fb.group(this.group);
        this.myapp.spinner.hide();
        this.getPackageDetail(this.packId);
      }
    });
  }

  // Function get package details
  async getPackageDetail(id) {
    console.log("calling package data");
    this.user = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      pack_id: id
    };
    this.api
      .callAPI(this.user, "admin/getPackageDetail")
      .subscribe(response => {
        if (response.status === true) {
          this.package = response.data.package;
          this.package_service = response.data.pack_service_items;

          //PAtch the form values
          this.packageForm.patchValue({
            pack_id: this.package["pack_id"],
            title: this.package["title"],
            start_date: this.package["start_date"],
            end_date: this.package["end_date"],
            validity: this.package["validity"],
            description: this.package["description"],
            price: this.package["price"],
            discount: this.package["discount"],
            discounted_price: this.package["discounted_price"],
            package_image: this.package["package_image"]
          });
          this.packageCost = this.package["price"];
          if (this.package["package_image"]) {
            this.imgPreviewPath =
              this.packageImgPath + this.package["package_image"];
          }

          //console.log("items:", this.package_service);
          this.package_service.forEach(data => {
            data.items.forEach(it => {
              //this.group["box_item_id_" + it.item_id] = new FormControl("");
              if (it.unit_type == "box") {
                this.packageForm.controls[
                  "box_item_id_" + it.service_item_id
                ].patchValue(it.quantity);
              } else {
                this.packageForm.controls[
                  "item_id_" + it.service_item_id
                ].patchValue(it.quantity);
              }
              const item = {
                item_id: it.service_item_id,
                price: it.price,
                qty: it.quantity
              };
              this.packItems.push(item);
            });
          });
        }
      });
  }

  //on File select, put the selected file in Form model variable
  onFileSelect(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // console.log("files" + files);
      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      if (fileType === "image") {
        this.packageForm.get("package_image").setValue(file);
        this.FileErrorMessageLog = false;
        //Preveiw of the image only on images
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          this.imgPreviewPath = reader.result;
        };
        return;
      } else {
        this.FileErrorMessageLog = true;
      }
    }
  }

  //form submit function
  onFormSubmit() {
    let canSubmit = true;
    if (this.discount.value >= 100) {
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Discount should be less than to 100%";
      canSubmit = false;
      return false;
    }
    this.myapp.hideAlerts();
    this.myapp.spinner.show();
    if (canSubmit) {
      //console.log("form data", this.packageForm.value);
      const formData = new FormData();
      console.log(formData);
      formData.append("pack_id", this.packageForm.get("pack_id").value);
      formData.append("title", this.packageForm.get("title").value);
      formData.append("description", this.packageForm.get("description").value);
      formData.append("start_date", this.packageForm.get("start_date").value);
      formData.append("end_date", this.packageForm.get("end_date").value);
      formData.append("price", this.packageForm.get("price").value);
      formData.append(
        "discounted_price",
        this.packageForm.get("discounted_price").value
      );
      formData.append(
        "package_image",
        this.packageForm.get("package_image").value
      );
      formData.append("validity", this.packageForm.get("validity").value);
      formData.append("discount", this.packageForm.get("discount").value);
      // Boxes
      this.service_box.forEach(data => {
        data.items.forEach(it => {
          formData.append(
            "box_item_id_" + it.item_id,
            this.packageForm.get("box_item_id_" + it.item_id).value
          );
        });
      });

      // Piece Items
      this.service_pieces.forEach(data => {
        data.items.forEach(it => {
          formData.append(
            "item_id_" + it.item_id,
            this.packageForm.get("item_id_" + it.item_id).value
          );
        });
      });

      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);
      //console.log("form data", formData);

      this.api
        .callAPI(formData, "admin/editPackage") //this.packageForm.value
        .subscribe(response => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.router.navigate(["../admin/view-packages"]);
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }

  //Getor - To access the fields directly in the form in HTML
  get pack_id() {
    return this.packageForm.get("pack_id");
  }
  get title() {
    return this.packageForm.get("title");
  }
  get description() {
    return this.packageForm.get("description");
  }
  get start_date() {
    return this.packageForm.get("start_date");
  }
  get end_date() {
    return this.packageForm.get("end_date");
  }
  get price() {
    return this.packageForm.get("price");
  }
  get discounted_price() {
    return this.packageForm.get("discounted_price");
  }
  get package_image() {
    return this.packageForm.get("package_image");
  }
  get validity() {
    return this.packageForm.get("validity");
  }
  get discount() {
    return this.packageForm.get("discount");
  }
  /*get box_service_name() {
    return this.packageForm.get("box_service_name") as FormArray;
  }
  get peice_service_name() {
    return this.packageForm.get("peice_service_name") as FormArray;
  }
  get service_item_id() {
    return this.packageForm.get("service_item_id") as FormArray;
  }
  get service_item_name() {
    return this.packageForm.get("service_item_name") as FormArray;
  }
  get service_item_price() {
    return this.packageForm.get("service_item_price") as FormArray;
  }
  get service_item_id2() {
    return this.packageForm.get("service_item_id2") as FormArray;
  }
  get service_item_name2() {
    return this.packageForm.get("service_item_name2") as FormArray;
  }
  get service_item_price2() {
    return this.packageForm.get("service_item_price2") as FormArray;
  }*/
  get box_items() {
    return this.packageForm.get("box_items");
  }
  get quantity() {
    return this.packageForm.get("quantity");
  }

  // Function to calculate pricing
  calculatePrice(qty: number, price, item_id) {
    /*service_id: service_id;
    service_item_id: item_id;
    service_item_name: item_name;
    this.qty = this.packageForm.get("quantity");*/

    if (!Number(qty) || qty < 0) {
      qty = 0;
    }

    const item = {
      item_id: item_id,
      price: price,
      qty: qty
    };

    // first remove the duplicate item_id
    for (var i = this.packItems.length - 1; i >= 0; --i) {
      if (this.packItems[i].item_id == item_id) {
        this.packItems.splice(i, 1);
      }
    }
    this.packItems.push(item);
    //console.log("items:", this.packItems);
    this.calculateFinalPrice();
  }
  calculateFinalPrice() {
    let total: any = 0;
    this.packItems.map(function(p) {
      let cost = p.qty * p.price;
      total = total + cost;
      //console.log("total: " + total);
    });
    this.packageCost = total.toFixed(2);
    this.calculateDiscount(this.discount.value);
  }

  calculateDiscount(discount) {
    if (!Number(discount)) {
      discount = 0;
    }

    if (discount <= 100) {
      let discountAmt = (this.packageCost / 100) * discount;
      let discounted_price = (this.packageCost - discountAmt).toFixed(2);

      this.packageForm.patchValue({
        discounted_price: discounted_price,
        price: this.packageCost
      });
    }
  }

  checkPriceLimit(event) {
    let value = event.target.value;
    //console.log(event.target.value);
    if (value.length >= 4) {
      //event.preventDefault();
      let number = value.substring(0, 4);
      event.target.value = number;
    }
  }
}
