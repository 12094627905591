import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { ApiService } from "../service/api.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  //Varialbe Declration
  loginForm: FormGroup;
  forgotForm: FormGroup;
  pageHeading = "Enter your email address and password to access the panel";
  invalidLogin: boolean = false;
  validForgot: boolean = false;
  invalidForgot: boolean = false;
  showForgotForm: boolean = false;
  showLoginForm: boolean = true;
  isLoadingResults = false;
  forgotMessage = "";
  loginMessage = "";
  passwordType = "password";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private api: ApiService
  ) {}

  ngOnInit() {
    /** spinner starts on init */
    /*this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);*/

    //Unset the Local Storage Varialbes
    //window.localStorage.removeItem("barId");
    //window.localStorage.removeItem("role_id");
    //localStorage.clear();

    //Redirect to Dashboard if user comes to login after successful login
    /*if (window.localStorage.getItem("isUserLogin")) {
      this.router.navigate(["/dashboard"]);
    }*/

    //form validation for the fields
    this.loginForm = this.fb.group({
      username: ["", [Validators.required, Validators.minLength(3)]],
      password: ["", [Validators.required]]
    });

    this.forgotForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]]
    });
  }

  showHidePassword(type) {
    this.passwordType = type;
  }

  //Getor - To access the username directly in the form in HTML
  get username() {
    return this.loginForm.get("username");
  }
  get password() {
    return this.loginForm.get("password");
  }
  get email() {
    return this.forgotForm.get("email");
  }

  //Show-Hide forgot/login form
  loadForgotForm() {
    this.pageHeading = "Enter your email to recover your password";
    this.showForgotForm = true;
    this.showLoginForm = false;
    this.invalidLogin = false;

    this.invalidForgot = false;
    this.validForgot = false;

    //Reset the form
    this.loginForm.reset();
    return false;
  }
  loadLoginForm() {
    this.pageHeading =
      "Enter your email address and password to access the panel";
    this.showForgotForm = false;
    this.showLoginForm = true;
    this.invalidLogin = false;

    this.invalidForgot = false;
    this.validForgot = false;
    //Reset the form
    this.forgotForm.reset();
    return false;
  }

  //Login Form Submit funtion
  onSubmit() {
    //Validation checking
    if (this.loginForm.invalid) {
      return;
    }

    // If Any User is already logged in, then redirect
    if (window.localStorage.getItem("role_id") == "1")
      window.location.href = "./admin/admin-dashboard";
    else if (window.localStorage.getItem("role_id") == "2")
      window.location.href = "./vendor/dashboard";

    this.spinner.show();

    // Login code start
    const loginPayLoad = this.loginForm.value;
    this.api.callAPI(loginPayLoad, "admin/login").subscribe(response => {
      //debugger;
      if (response.status === true) {
        this.spinner.hide();
        //console.log(response);
        //console.log(response.data.id);
        window.localStorage.setItem("role_id", response.data.role_id);
        window.localStorage.setItem("isUserLogin", "1");
        window.localStorage.setItem("loginUserKiId", response.data.user_id);
        window.localStorage.setItem("token", response.data.token);

        if (response.data.role_id == "2") {
          //Bar Login
          window.localStorage.setItem("vendor_id", response.data.vendor_id);
          window.localStorage.setItem("vendor_name", response.data.vendor_name);
          window.location.href = "./vendor/dashboard";
        } else {
          //Admin Login
          window.localStorage.setItem("userId", response.data.user_id);
          window.location.href = "./admin/admin-dashboard";
        }

        //this.router.navigate(["/dashboard"]);
      } else {
        this.invalidLogin = true;
        this.loginMessage = response.message;
        console.log(response.message);
        this.spinner.hide();
      }
    });
  }

  //Forgot Form Submit funtion
  onForgotSubmit() {
    //Validation checking
    if (this.forgotForm.invalid) {
      return;
    }
    //console.log(this.forgotForm.value);

    this.spinner.show();
    this.isLoadingResults = true;
    const forgotPayLoad = this.forgotForm.value;
    //console.log(forgotPayLoad);
    this.api
      .callAPI(forgotPayLoad, "admin/forgotPassword")
      .subscribe(response => {
        if (response.status === true) {
          this.spinner.hide();
          this.isLoadingResults = false;
          this.validForgot = true;
          this.invalidForgot = false;
          //console.log(response.message);
          this.forgotMessage = response.message;
          this.forgotForm.reset();
        } else {
          this.spinner.hide();
          this.isLoadingResults = false;
          this.invalidForgot = true;
          this.validForgot = false;
          this.forgotMessage = response.message;
          //console.log(response.message);
        }
      });
  }
}
