import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";
import { interval, Subscription } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  public adminMenu = false;
  public userLable = "";
  public count = [];
  mySubscription: Subscription;
  public vendorMenu = false;
  notification = [];
  isDelete = true;
  user = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    public spinner: NgxSpinnerService,
    public api: ApiService
  ) {}

  ngOnInit() {
    //Call the notification after each 30 sec
    this.mySubscription = interval(30000).subscribe(x => {
      this.getNotifications("limit");
    });

    this.getNotifications("limit");
    if (window.localStorage.getItem("role_id") == "1") {
      this.adminMenu = true;
      this.userLable = "Hello Admin";
    } else {
      this.vendorMenu = true;
      this.userLable = "Hello " + localStorage.getItem("vendor_name");
    }
  }

  getNotifications(limit) {
    if (window.localStorage.getItem("role_id") == "1") {
      this.user = {
        user_id: this.myapp.loginUserkiId,
        token: this.myapp.token,
        noti_type: limit
      };
      this.api
        .callAPI(this.user, "admin/getNotifications")
        .subscribe(response => {
          if (response.status === true) {
            this.notification = response.data;
            this.count = response.count;
            this.myapp.notificationCount = response.count;
          } else if (response.status === "expired") {
            this.myapp.logout();
          } else {
            this.notification = [];
            this.count = [];
            this.isDelete = false;
          }
        });
    } else if (window.localStorage.getItem("role_id") == "2") {
      this.user = {
        vendor_id: this.myapp.vendorUserId,
        user_id: this.myapp.loginUserkiId,
        token: this.myapp.token,
        noti_type: limit
      };
      this.api
        .callAPI(this.user, "vendor/getNotification")
        .subscribe(response => {
          if (response.status === true) {
            this.notification = response.data;
            this.count = response.count;
            this.myapp.notificationCount = response.count;
          } else if (response.status === "expired") {
            this.myapp.logout();
          } else {
            this.notification = [];
            this.count = [];
            this.isDelete = false;
          }
        });
    }
  }

  clearAll() {
    let conf = confirm("Do you want to perform this action?");
    if (conf) {
      this.spinner.show();
      this.api
        .callAPI(this.myapp.loginUser, "admin/clearNotifications")
        .subscribe(response => {
          this.spinner.hide();
          if (response.status === true) {
            this.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.isDelete = false;
            this.count = [];
            this.notification = [];
            this.myapp.notificationCount = 0;
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }
}
