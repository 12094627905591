import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/service/api.service";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-manage-boxes",
  templateUrl: "./manage-boxes.component.html",
  styleUrls: ["./manage-boxes.component.css"]
})
export class ManageBoxesComponent implements OnInit {
  public boxes = [];
  public boxForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private api: ApiService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Form Model Declaration with form validation for the fields
    this.boxForm = this.fb.group({
      small_box_id: [""],
      small_min_qty: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      small_max_qty: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      medium_box_id: [""],
      medium_min_qty: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      medium_max_qty: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      large_box_id: [""],
      large_min_qty: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      large_max_qty: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ]
    });
    this.getAllBoxes();
  }

  getAllBoxes() {
    this.api
      .callAPI(this.myapp.loginUser, "admin/getAllBoxes")
      .subscribe(response => {
        if (response.status === true) {
          this.boxes = response.data;
          //console.log("Small box qty:", this.boxes[0]["min_qty"]);
          this.boxForm.patchValue({
            small_box_id: this.boxes[0]["box_id"],
            small_min_qty: this.boxes[0]["min_qty"],
            small_max_qty: this.boxes[0]["max_qty"],
            medium_box_id: this.boxes[1]["box_id"],
            medium_min_qty: this.boxes[1]["min_qty"],
            medium_max_qty: this.boxes[1]["max_qty"],
            large_box_id: this.boxes[2]["box_id"],
            large_min_qty: this.boxes[2]["min_qty"],
            large_max_qty: this.boxes[2]["max_qty"]
          });
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
  }

  //form submit function
  onFormSubmit() {
    let canSubmit = true;
    if (canSubmit) {
      this.myapp.spinner.show();
      const formData = new FormData();
      formData.append("small_box_id", this.boxForm.get("small_box_id").value);
      formData.append("small_min_qty", this.small_min_qty.value);
      formData.append("small_max_qty", this.small_max_qty.value);

      formData.append("medium_box_id", this.boxForm.get("medium_box_id").value);
      formData.append("medium_min_qty", this.medium_min_qty.value);
      formData.append("medium_max_qty", this.medium_max_qty.value);

      formData.append("large_box_id", this.boxForm.get("large_box_id").value);
      formData.append("large_min_qty", this.large_min_qty.value);
      formData.append("large_max_qty", this.large_max_qty.value);

      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);

      //console.log("Service Image:" + this.service_image.value);
      this.api.callAPI(formData, "admin/manageBoxes").subscribe(response => {
        if (response.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
    }
  }

  //Getor - To access the fields directly in the form in HTML
  get small_min_qty() {
    return this.boxForm.get("small_min_qty");
  }
  get small_max_qty() {
    return this.boxForm.get("small_max_qty");
  }
  get medium_min_qty() {
    return this.boxForm.get("medium_min_qty");
  }
  get medium_max_qty() {
    return this.boxForm.get("medium_max_qty");
  }
  get large_min_qty() {
    return this.boxForm.get("large_min_qty");
  }
  get large_max_qty() {
    return this.boxForm.get("large_max_qty");
  }
}
