import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-assign-driver",
  templateUrl: "./assign-driver.component.html",
  styleUrls: ["./assign-driver.component.css"]
})
export class AssignDriverComponent implements OnInit {
  public user = {};
  assignRequest = [];
  successMessage = "";
  failMessage = "";
  page = 1; //used for load more
  hideLoadMore = true;
  pageHeading = "";
  orderHeading = "";
  public assignDriverForm: FormGroup;
  drivers = [];
  showModal = true;
  filterForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    public spinner: NgxSpinnerService,
    public api: ApiService
  ) {}

  ngOnInit() {
    this.pageHeading = "Assign Driver";
    this.orderHeading = "Order Management";
    this.getAllAssignRequest("");
    this.getDrivers();

    //Form Model Declaration with form validation for the fields
    this.assignDriverForm = this.fb.group({
      driver_id: ["", [Validators.required]],
      date: ["", [Validators.required]],
      request_id: [""],
      vendor_id: [""],
      order_id: [""],
      assign_type: [""]
    });

    this.filterForm = this.fb.group({
      keyword: [""]
    });
  }

  getAllAssignRequest(keyword) {
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", keyword);
    this.api
      .callAPI(formData, "admin/assignRequestList")
      .subscribe(response => {
        //console.log(response);
        if (response.status === true) {
          this.myapp.spinner.hide();
          this.assignRequest = response.data;
          console.log(this.assignRequest);
          // this.myapp.showSuccessAlert = true;
          // this.myapp.successMsg = response.message;
        } else {
          this.myapp.spinner.hide();
          this.assignRequest = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      });
  }

  // Function to get all drivers
  getDrivers() {
    this.api
      .callAPI(this.myapp.loginUser, "admin/getAllSelfDrivers")
      .subscribe(response => {
        if (response.status === true) {
          this.drivers = response.data;
          // this.myapp.showSuccessAlert = true;
          // this.myapp.successMsg = response.message;
        }
      });
  }

  //form submit function
  onFormSubmit() {
    this.myapp.hideAlerts();
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("driver_id", this.assignDriverForm.get("driver_id").value);
    formData.append("date", this.assignDriverForm.get("date").value);
    formData.append("vendor_id", this.assignDriverForm.get("vendor_id").value);
    formData.append("order_id", this.assignDriverForm.get("order_id").value);
    formData.append(
      "request_id",
      this.assignDriverForm.get("request_id").value
    );
    formData.append(
      "assign_type",
      this.assignDriverForm.get("assign_type").value
    );
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);

    //console.log("Service Image:" + this.service_image.value);
    this.api.callAPI(formData, "admin/assignDriver").subscribe(response => {
      if (response.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;

        // Hide the Modal
        this.showModal = false;
        let closeModal: HTMLElement = document.getElementsByClassName(
          "body"
        )[0] as HTMLElement;
        closeModal.classList.remove("modal-open");
        let closeModalpadding: HTMLElement = document.getElementsByClassName(
          "body"
        )[0] as HTMLElement;
        closeModalpadding.style.padding = "0px";
        let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
          "modal-backdrop"
        )[0] as HTMLElement;
        ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);

        this.ngOnInit();
        this.assignDriverForm.reset();
      } else {
        this.myapp.spinner.hide();
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    this.myapp.spinner.show();

    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", this.filterForm.get("keyword").value);
    formData.append("page", pageNo);

    this.api
      .callAPI(formData, "admin/assignRequestList")
      .subscribe((response: any) => {
        if (response.status == true) {
          this.myapp.spinner.hide();
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;
          for (let i = 0; i < response.data.length; i++) {
            this.assignRequest.push(response.data[i]);
          }
          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.spinner.hide();
          this.myapp.isLoadingResults = false;
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
          this.hideLoadMore = false;
        }
      });
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.assignDriverForm.reset();
    //Patch the required data in Form Modal
    this.assignDriverForm.patchValue({
      request_id: "",
      driver_id: "",
      date: "",
      vendor_id: "",
      order_id: "",
      assign_type: ""
    });
  }

  //Getor - To access the fields directly in the form in HTML
  get driver_id() {
    return this.assignDriverForm.get("driver_id");
  }
  get order_id() {
    return this.assignDriverForm.get("order_id");
  }
  get date() {
    return this.assignDriverForm.get("date");
  }
  get vendor_id() {
    return this.assignDriverForm.get("vendor_id");
  }
  get assign_type() {
    return this.assignDriverForm.get("assign_type");
  }
  get request_id() {
    return this.assignDriverForm.get(this.request_id);
  }

  assignDriver(request_id, vendor_id, order_id, trip_type, date) {
    console.log(date);
    this.assignDriverForm.patchValue({
      request_id: request_id,
      vendor_id: vendor_id,
      order_id: order_id,
      assign_type: trip_type,
      date: date
    });
  }

  //On Keyword filter
  filterData() {
    let keyword = this.filterForm.get("keyword").value;
    this.getAllAssignRequest(keyword);
  }
}
