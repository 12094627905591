import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.css"],
})
export class EmailComponent implements OnInit {
  requestChangeEmail: FormGroup;
  successMessage = "";
  failMessage = "";
  pageHeading = "";
  user_verification_id = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public myapp: AppComponent,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.pageHeading = "Change Email";
    //Form Model Declaration with form validation for the fields
    this.requestChangeEmail = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
    });
  }

  //form submit function
  onFormSubmit() {
    this.myapp.showSuccessAlert = false;
    this.myapp.showErrorAlert = false;
    this.myapp.successMsg = "";
    this.myapp.errorMsg = "";

    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("email", this.requestChangeEmail.get("email").value);
    formData.append("vendor_id", this.myapp.vendorUserId);
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);

    this.api
      .callAPI(formData, "vendor/requestChangeMyEmail2")
      .subscribe((response) => {
        if (response.status) {
          this.myapp.spinner.hide();
          localStorage.removeItem("value");
          localStorage.removeItem("otp");
          window.localStorage.setItem("value", "2");
          window.localStorage.setItem("otp", response.data.otp);

          this.router.navigate([
            "../vendor/verify-email-otp/" + response.data.user_verification_id,
          ]);
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          console.log(response.message);
        }
      });
  }

  //Getor - To access the fields directly in the form in HTML
  get email() {
    return this.requestChangeEmail.get("email");
  }
}
