import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/service/api.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-view-service-items",
  templateUrl: "./view-service-items.component.html",
  styleUrls: ["./view-service-items.component.css"]
})
export class ViewServiceItemsComponent implements OnInit {
  public service = null;
  public items = [];
  service_id = null;
  public serviceImgPath = this.api.serviceImgPath();
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private api: ApiService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.service_id = id;
        this.getServiceItems(id);
      }
    });
  }

  getServiceItems(id) {
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("service_id", id);

    this.api.callAPI(formData, "admin/getServiceDetail").subscribe(response => {
      if (response.status === true) {
        this.service = response.data.service;
        this.items = response.data.items;
        //console.log("Service DAta:" + this.service);
        //console.log("items DAta:" + this.items);
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  changeServiceStatus(status) {
    let msg = "";
    if (status == 0) {
      msg = "Do you want to suspend this service?";
    } else {
      msg = "Do you want to activate this service?";
    }
    let conf = confirm(msg);
    if (conf) {
      this.updateServiceStatus(status);
    }
    return false;
  }

  updateServiceStatus(status) {
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("service_id", this.service_id);
    formData.append("status", status);

    this.api
      .callAPI(formData, "admin/updateServiceStatus")
      .subscribe(response => {
        if (response.status === true) {
          this.myapp.spinner.hide();
          this.service.status = status;
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
  }
}
