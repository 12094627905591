import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-view-vendors",
  templateUrl: "./view-vendors.component.html",
  styleUrls: ["./view-vendors.component.css"],
})
export class ViewVendorsComponent implements OnInit {
  vendors: any = [];
  total_vendor = 0;
  active_vendor = 0;
  filterForm: FormGroup;
  successMessage = "";
  failMessage = "";
  pageHeading = "";
  vendors_data: any = [];
  public profilePath = null;
  user = {};
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private api: ApiService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
    });

    this.getAllVendors("");
    this.pageHeading = "Vendor Management";
  }

  getAllVendors(keyword) {
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", keyword);
    this.api.callAPI(formData, "admin/getVendors").subscribe((response) => {
      if (response.status === true) {
        this.vendors_data = response.data;
        this.vendors = response.data.all_vendors;
        //console.log("vendor data", this.vendors);
        this.active_vendor = response.data.active_vendor;
        this.total_vendor = response.data.total_vendor;
        // this.successMessage = response.message;
      } else {
        this.vendors = [];
        this.vendors_data = [];
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //On Keyword filter
  filterData() {
    let keyword = this.filterForm.get("keyword").value;
    console.log(keyword);
    this.getAllVendors(keyword);
  }
}
