import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/service/api.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-view-services",
  templateUrl: "./view-services.component.html",
  styleUrls: ["./view-services.component.css"]
})
export class ViewServicesComponent implements OnInit {
  filterForm: FormGroup;
  public services: any = [];
  successMessage = "";
  failMessage = "";
  public serviceImgPath = this.api.serviceImgPath();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private api: ApiService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""]
    });

    this.getAllServices("");
  }

  //Fucntion to call all the services
  getAllServices(keyword) {
    //this.spinner.show();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", keyword);

    this.api.callAPI(formData, "admin/getServices").subscribe(response => {
      if (response.status === true) {
        this.services = response.data;
        //console.log("Service DAta:" + this.services);
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //On Keyword filter
  filterData() {
    let keyword = this.filterForm.get("keyword").value;
    this.getAllServices(keyword);
  }
}
