import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { ApiService } from "src/app/service/api.service";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-vendor-add-driver",
  templateUrl: "./vendor-add-driver.component.html",
  styleUrls: ["./vendor-add-driver.component.css"]
})
export class VendorAddDriverComponent implements OnInit {
  public pageHeading = "Add Driver";
  imgPreviewPath = null;
  FileErrorMessageLog = false;
  public driverForm: FormGroup;
  addDriver = true;
  imageChangedEvent: any = "";
  imageSave = false;
  imageHide = false;
  user = {};
  chooseFileLabel = "Choose a file...";
  alertMsg = "Driver created successfully";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}
  ngOnInit() {
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadUserData(id);
        this.pageHeading = "Edit Driver";
        this.addDriver = false;
        this.alertMsg = "Driver updated successfully";
      }
    });

    this.myapp.successMsg = "";
    this.myapp.errorMsg = "";
    /*this.myapp.spinner.show();
    setTimeout(() => {
      this.myapp.spinner.hide();
    }, 2000);*/

    //Form Model Declaration with form validation for the fields
    this.driverForm = this.fb.group({
      driver_user_id: [""],
      driver_name: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.pattern(/^[a-zA-Z\s]*$/)
        ]
      ],
      country_code: ["", [Validators.required]],
      mobile_no: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(10),
          Validators.pattern("^[0-9()]+$")
        ]
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(100)
        ]
      ],
      password: [""],
      gender: [""],
      date_of_birth: ["", [Validators.required]],
      bank_name: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
          Validators.pattern(/^[a-zA-Z\s]*$/)
        ]
      ],
      account_holder_name: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.pattern(/^[a-zA-Z\s]*$/)
        ]
      ],
      account_no: [
        "",
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(18),
          Validators.pattern("^[A-Za-z0-9]+$")
        ]
      ],
      vechile_registration_no: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
          Validators.pattern("^[A-Za-z0-9]+$")
        ]
      ],
      modal_no: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(17),
          Validators.pattern("^[A-Za-z0-9]+$")
        ]
      ],
      driving_license_no: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          Validators.pattern("^[A-Za-z0-9]+$")
        ]
      ],
      driving_license_version: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(30)]
      ],
      profile_image: [""]
    });
  }

  //load data in edit form
  loadUserData(id) {
    this.user = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      driver_user_id: id
    };
    this.myapp.spinner.show();
    this.api
      .callAPI(this.user, "vendor/getDriverProfile")
      .subscribe(response => {
        if (response.status === true) {
          this.myapp.spinner.hide();
          //Patch the Item data in menuForm
          this.driverForm.patchValue({
            driver_user_id: id,
            driver_name:
              response.data.driver_data.first_name +
              " " +
              response.data.driver_data.last_name,
            mobile_no: response.data.driver_data.mobile_number,
            email: response.data.driver_data.email,
            country_code: response.data.driver_data.country_code,
            date_of_birth: response.data.driver_data.date_of_birth,
            bank_name: response.data.driver_data.bank_name,
            account_holder_name: response.data.driver_data.account_holder_name,
            account_no: response.data.driver_data.account_no,
            vechile_registration_no:
              response.data.driver_data.vechile_registration_no,
            modal_no: response.data.driver_data.modal_no,
            driving_license_no: response.data.driver_data.driving_license_no,
            driving_license_version:
              response.data.driver_data.driving_license_version,
            profile_image: response.data.driver_data.profile_image,
            gender: response.data.driver_data.gender
          });

          if (response.data.driver_data.profile_image) {
            this.imgPreviewPath = response.data.driver_data.profile_image;
          }

          //this.myapp.showSuccessAlert = true;
          //this.myapp.successMsg = response.message;
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          console.log(response.message);
        }
      });
  }

  //on File select, put the selected file in Form model variable
  onFileSelect(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // console.log("files" + files);
      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      if (fileType === "image") {
        this.imageHide = true;
        this.imageSave = true;

        this.driverForm.get("profile_image").setValue(file);
        this.imageChangedEvent = event;
        this.FileErrorMessageLog = false;
        //Preveiw of the image only on images
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          this.imgPreviewPath = reader.result;
        };
        return;
      } else {
        this.FileErrorMessageLog = true;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log("Cropping: " + JSON.stringify(event));
    this.imgPreviewPath = event.base64;
    this.driverForm.get("profile_image").setValue(event.base64);
  }

  saveCropImg() {
    this.imageSave = false;
  }

  //form submit function
  onFormSubmit() {
    this.myapp.spinner.show();
    const formData = new FormData();
    console.log(formData);
    formData.append(
      "driver_user_id",
      this.driverForm.get("driver_user_id").value
    );
    formData.append("driver_name", this.driverForm.get("driver_name").value);
    formData.append("country_code", this.driverForm.get("country_code").value);
    formData.append("mobile_no", this.driverForm.get("mobile_no").value);
    formData.append("email", this.driverForm.get("email").value);
    formData.append("password", this.driverForm.get("password").value);
    formData.append("gender", this.driverForm.get("gender").value);
    formData.append(
      "date_of_birth",
      this.driverForm.get("date_of_birth").value
    );
    formData.append("bank_name", this.driverForm.get("bank_name").value);
    formData.append(
      "account_holder_name",
      this.driverForm.get("account_holder_name").value
    );
    formData.append("account_no", this.driverForm.get("account_no").value);
    formData.append(
      "vechile_registration_no",
      this.driverForm.get("vechile_registration_no").value
    );
    formData.append("modal_no", this.driverForm.get("modal_no").value);
    formData.append(
      "driving_license_no",
      this.driverForm.get("driving_license_no").value
    );
    formData.append(
      "driving_license_version",
      this.driverForm.get("driving_license_version").value
    );
    formData.append(
      "profile_image",
      this.driverForm.get("profile_image").value
    );
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);

    //console.log("Service Image:" + this.service_image.value);
    this.api.callAPI(formData, "vendor/addDriver").subscribe(response => {
      if (response.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = this.alertMsg; //response.message;
        this.router.navigate(["../vendor/view-drivers"]);
      } else {
        this.myapp.spinner.hide();
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.driverForm.reset();
    //Patch the required data in Form Modal
    this.driverForm.patchValue({
      driver_user_id: "",
      driver_name: "",
      country_code: "",
      mobile_no: "",
      email: "",
      password: "",
      gender: "",
      date_of_birth: "",
      bank_name: "",
      account_holder_name: "",
      account_no: "",
      vechile_registration_no: "",
      modal_no: "",
      driving_license_no: "",
      driving_license_version: "",
      profile_image: ""
    });
  }

  //Getor - To access the fields directly in the form in HTML
  get driver_name() {
    return this.driverForm.get("driver_name");
  }
  get country_code() {
    return this.driverForm.get("country_code");
  }
  get password() {
    return this.driverForm.get("password");
  }
  get mobile_no() {
    return this.driverForm.get("mobile_no");
  }
  get email() {
    return this.driverForm.get("email");
  }
  get gender() {
    return this.driverForm.get("gender");
  }
  get date_of_birth() {
    return this.driverForm.get("date_of_birth");
  }
  get bank_name() {
    return this.driverForm.get("bank_name");
  }
  get account_holder_name() {
    return this.driverForm.get("account_holder_name");
  }
  get account_no() {
    return this.driverForm.get("account_no");
  }
  get profile_image() {
    return this.driverForm.get("profile_image");
  }
  get vechile_registration_no() {
    return this.driverForm.get("vechile_registration_no");
  }
  get modal_no() {
    return this.driverForm.get("modal_no");
  }
  get driving_license_no() {
    return this.driverForm.get("driving_license_no");
  }
  get driving_license_version() {
    return this.driverForm.get("driving_license_version");
  }
}
