import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppComponent } from "../../app.component";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { Subscription, interval } from "rxjs";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  public user = {};
  public dashboard: any = [];
  successMessage = "";
  isNotAssigned = false;
  failMessage = "";
  pageHeading = "";
  public assignDriverForm: FormGroup;
  drivers: any = [];
  count: any = [];
  dashSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    public spinner: NgxSpinnerService,
    public api: ApiService
  ) {}

  ngOnInit() {
    this.pageHeading = "Dashboard";
    this.getDashboard();
    this.getDrivers();

    //Call the notification after each 30 sec
    this.dashSubscription = interval(60000).subscribe((x) => {
      this.getDashboard();
    });

    //Form Model Declaration with form validation for the fields
    this.assignDriverForm = this.fb.group({
      driver_id: ["", [Validators.required]],
      date: ["", [Validators.required]],
      request_id: [""],
      vendor_id: [""],
      order_id: [""],
      assign_type: [""],
    });
  }

  getDashboard() {
    this.api
      .callAPI(this.myapp.loginVendorUser, "vendor/dashboard")
      .subscribe((response) => {
        if (response.status === true) {
          this.dashboard = response.data.orders;
          this.count = response.data;
          //console.log(this.count);
        } else {
          this.dashboard = [];
          this.count = [];
        }
      });
  }

  // Function to get all drivers
  getDrivers() {
    this.api
      .callAPI(this.myapp.loginVendorUser, "admin/getAllSelfDrivers")
      .subscribe((response) => {
        if (response.status === true) {
          this.drivers = response.data;
          console.log(this.drivers);
        }
      });
  }

  //form submit function
  onFormSubmit() {
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("driver_id", this.assignDriverForm.get("driver_id").value);
    formData.append("date", this.assignDriverForm.get("date").value);
    formData.append("vendor_id", this.assignDriverForm.get("vendor_id").value);
    formData.append("order_id", this.assignDriverForm.get("order_id").value);
    formData.append(
      "request_id",
      this.assignDriverForm.get("request_id").value
    );
    formData.append(
      "assign_type",
      this.assignDriverForm.get("assign_type").value
    );
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);

    //console.log("Service Image:" + this.service_image.value);
    this.api.callAPI(formData, "admin/assignDriver").subscribe((response) => {
      if (response.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        this.myapp.spinner.hide();
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.assignDriverForm.reset();
    //Patch the required data in Form Modal
    this.assignDriverForm.patchValue({
      request_id: "",
      driver_id: "",
      date: "",
      vendor_id: "",
      order_id: "",
      assign_type: "",
    });
  }

  //Getor - To access the fields directly in the form in HTML
  get driver_id() {
    return this.assignDriverForm.get("driver_id");
  }
  get order_id() {
    return this.assignDriverForm.get("order_id");
  }
  get date() {
    return this.assignDriverForm.get("date");
  }
  get vendor_id() {
    return this.assignDriverForm.get("vendor_id");
  }
  get assign_type() {
    return this.assignDriverForm.get("assign_type");
  }
  get request_id() {
    return this.assignDriverForm.get(this.request_id);
  }

  assignDriver(request_id, vendor_id, order_id, trip_type) {
    this.assignDriverForm.patchValue({
      request_id: request_id,
      vendor_id: vendor_id,
      order_id: order_id,
      assign_type: trip_type,
    });
  }

  refreshDashboard() {
    this.getDashboard();
  }
}
