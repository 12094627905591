import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-add-transaction",
  templateUrl: "./add-transaction.component.html",
  styleUrls: ["./add-transaction.component.css"]
})
export class AddTransactionComponent implements OnInit {
  public pageHeading = "Add Transaction";
  isVendor = false;
  isDriver = false;
  imgPreviewPath = null;
  FileErrorMessageLog = false;
  public transactionForm: FormGroup;
  addDriver = true;
  successMessage = "";
  failMessage = "";
  user = {};
  drivers: any = [];
  vendors: any = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService,
    public spinner: NgxSpinnerService
  ) {}
  ngOnInit() {
    this.loadVendor();
    this.loadDriver();

    this.myapp.successMsg = "";
    this.myapp.errorMsg = "";
    /*this.myapp.spinner.show();
    setTimeout(() => {
      this.myapp.spinner.hide();
    }, 2000);*/

    //Form Model Declaration with form validation for the fields
    this.transactionForm = this.fb.group({
      paid_to_id: ["", [Validators.required]],
      option: [""],
      transaction_id: [
        "",
        [Validators.required, Validators.minLength(2), Validators.maxLength(50)]
      ],
      amount: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]+(.[0-9]{1,2})?$/)
        ]
      ],
      date: ["", [Validators.required]],
      user_type: ["", [Validators.required]],
      description: [""]
    });
  }

  loadVendor() {
    this.user = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token
    };
    this.spinner.show();
    this.api.callAPI(this.user, "admin/getAllVendors").subscribe(response => {
      this.spinner.hide();
      if (response.status === true) {
        this.spinner.hide();
        this.vendors = response.data;
        console.log(this.vendors);
        //this.myapp.showSuccessAlert = true;
        //this.myapp.successMsg = response.message;
      } else {
        this.spinner.hide();
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        //console.log(response.message);
      }
    });
  }

  loadDriver() {
    this.user = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token
    };
    this.spinner.show();
    this.api.callAPI(this.user, "admin/getAllDrivers").subscribe(response => {
      this.spinner.hide();
      if (response.status === true) {
        this.spinner.hide();
        this.drivers = response.data;
        console.log(this.drivers);
        //this.successMessage = response.message;
      } else {
        this.spinner.hide();
        this.failMessage = response.message;
        //console.log(response.message);
      }
    });
  }

  //form submit function
  onFormSubmit() {
    let canSubmit = true;
    let amount = this.amount.value;
    if (amount <= 0) {
      canSubmit = false;
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please enter a valid amount";
      return false;
    }

    let paid_to_id = this.paid_to_id.value;
    if (paid_to_id == "Select Driver" || paid_to_id == "Select Vendor") {
      canSubmit = false;
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please select an user for the payment";
      return false;
    }

    if (canSubmit) {
      this.myapp.hideAlerts();
      this.myapp.spinner.show();
      const formData = new FormData();
      formData.append(
        "paid_to_id",
        this.transactionForm.get("paid_to_id").value
      );
      formData.append(
        "transaction_id",
        this.transactionForm.get("transaction_id").value
      );
      formData.append("amount", this.transactionForm.get("amount").value);
      formData.append("date", this.transactionForm.get("date").value);
      formData.append("user_type", this.transactionForm.get("user_type").value);
      formData.append(
        "description",
        this.transactionForm.get("description").value
      );
      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);

      //console.log("Service Image:" + this.service_image.value);
      this.api.callAPI(formData, "admin/makePayment").subscribe(response => {
        if (response.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          //console.log(this.transactionForm.get("user_type").value);

          if (this.transactionForm.get("user_type").value == "vendor") {
            this.router.navigate(["../admin/vendor-transactions"]);
          } else {
            this.router.navigate(["../admin/driver-transactions"]);
          }
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
    }
  }

  transUserType(type) {
    if (type == "vendor") {
      this.transactionForm.patchValue({
        user_type: type,
        paid_to_id: "Select Vendor"
        /*transaction_id: "",
        date: "",
        amount: "",
        description: ""*/
      });
      this.isVendor = true;
      this.isDriver = false;
    }
    if (type == "driver") {
      this.transactionForm.patchValue({
        user_type: type,
        paid_to_id: "Select Driver"
        /*transaction_id: "",
        date: "",
        amount: "",
        description: ""*/
      });
      this.isDriver = true;
      this.isVendor = false;
    }
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.transactionForm.reset();
    //Patch the required data in Form Modal
    this.transactionForm.patchValue({
      paid_to_id: "",
      transaction_id: "",
      amount: "",
      date: "",
      user_type: "",
      description: ""
    });
    this.isDriver = false;
    this.isVendor = false;
  }

  //Getor - To access the fields directly in the form in HTML
  get paid_to_id() {
    return this.transactionForm.get("paid_to_id");
  }
  get transaction_id() {
    return this.transactionForm.get("transaction_id");
  }
  get amount() {
    return this.transactionForm.get("amount");
  }
  get date() {
    return this.transactionForm.get("date");
  }
  get user_type() {
    return this.transactionForm.get("user_type");
  }
  get description() {
    return this.transactionForm.get("description");
  }
}
