import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-vendor-view-order",
  templateUrl: "./vendor-view-order.component.html",
  styleUrls: ["./vendor-view-order.component.css"]
})
export class VendorViewOrderComponent implements OnInit {
  public orders: any = [];
  vendors: any = [];
  successMessage = "";
  failMessage = "";
  pageHeading = "";
  public ordersCount: any = [];
  public profilePath = null;
  page = 1; //used for load more
  hideLoadMore = true;
  changeVendorForm: FormGroup;
  filterForm: FormGroup;
  order = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public myapp: AppComponent,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.getAllOrders("", "", "");
    this.pageHeading = "Order Management";

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      vendor: [""],
      order_status: [""],
      date: [""]
    });

    //Form Model Declaration with form validation for the fields
    this.changeVendorForm = this.fb.group({
      vendor_id: ["", [Validators.required]],
      order_id: [""]
    });
  }

  getAllOrders(keyword, order_status, date) {
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("vendor_id", this.myapp.vendorUserId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", keyword);
    formData.append("order_status", order_status);
    formData.append("date", date);

    this.api.callAPI(formData, "vendor/getOrders").subscribe(response => {
      if (response.status === true) {
        this.ordersCount = response.data;
        this.orders = response.data.order_data;
        //console.log(this.orders);
        //console.log(this.ordersCount);
      } else {
        //this.ordersCount = [];
        this.orders = [];
      }
    });
  }

  // Function to get all drivers
  getVendors() {
    this.api
      .callAPI(this.myapp.loginUser, "vendor/getAllVendors")
      .subscribe(response => {
        if (response.status === true) {
          this.vendors = response.data;
        }
      });
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.changeVendorForm.reset();
    //Patch the required data in Form Modal
    this.changeVendorForm.patchValue({
      vendor_id: "",
      order_id: ""
    });
  }

  //Getor - To access the fields directly in the form in HTML
  get order_id() {
    return this.changeVendorForm.get("order_id");
  }
  get vendor_id() {
    return this.changeVendorForm.get("vendor_id");
  }

  changeVendor(order_id) {
    this.changeVendorForm.patchValue({
      order_id: order_id
    });
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    //console.log(pageNo);
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("vendor_id", this.myapp.vendorUserId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", this.filterForm.get("keyword").value);
    formData.append("order_status", this.filterForm.get("order_status").value);
    formData.append("page", pageNo);

    this.api
      .callAPI(formData, "vendor/getOrders")
      .subscribe((response: any) => {
        if (response.status == true) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;
          for (let i = 0; i < response.data.order_data.length; i++) {
            this.orders.push(response.data.order_data[i]);
          }
          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.order_data.length)
            this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
          this.hideLoadMore = false;
        }
      });
  }

  //On Keyword filter
  filterData() {
    this.hideLoadMore = true;
    this.page = 1;
    let date = this.filterForm.get("date").value;
    let keyword = this.filterForm.get("keyword").value;
    let order_status = this.filterForm.get("order_status").value;
    console.log(order_status);
    this.getAllOrders(keyword, order_status, date);
  }
}
