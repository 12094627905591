import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-vendor-transaction",
  templateUrl: "./vendor-transaction.component.html",
  styleUrls: ["./vendor-transaction.component.css"]
})
export class VendorTransactionComponent implements OnInit {
  public vendorTrans: any = [];
  successMessage = "";
  failMessage = "";
  pageHeading = "";
  public profilePath = null;
  page = 1; //used for load more
  hideLoadMore = true;
  user = {};
  filterForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private spinner: NgxSpinnerService,
    private api: ApiService
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""]
    });

    this.getVendorTrans("");
    this.pageHeading = "Vendor Transactions";
  }

  getVendorTrans(keyword) {
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", keyword);
    this.api
      .callAPI(formData, "admin/getVendorTransactions")
      .subscribe(response => {
        if (response.status === true) {
          this.spinner.hide();
          this.vendorTrans = response.data;
          //console.log("length:" + this.vendorTrans.length);

          //if (this.vendorTrans.length <= response.limit)
          //this.hideLoadMore = false;
        } else {
          this.vendorTrans = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      });
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    console.log(pageNo);
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", this.filterForm.get("keyword").value);
    formData.append("page", pageNo);

    this.api
      .callAPI(formData, "admin/getVendorTransactions")
      .subscribe((response: any) => {
        if (response.status == true) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;
          console.log(response.data.length);
          for (let i = 0; i < response.data.length; i++) {
            this.vendorTrans.push(response.data[i]);
          }
          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = "No more record available";
          this.hideLoadMore = false;
        }
      });
  }

  //On Keyword filter
  filterData() {
    this.hideLoadMore = true;
    this.page = 1;

    let keyword = this.filterForm.get("keyword").value;
    this.getVendorTrans(keyword);
  }
}
