import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Router, ParamMap, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.css"],
})
export class OrderDetailComponent implements OnInit {
  statusForm: FormGroup;
  pick_up_detail: any = [];
  drop_off_detail: any = [];
  customer_data: any = [];
  vendor_data: any = [];
  order_details: any = [];
  order_items: any = [];
  order_status: any = [];
  public orderParam = {};
  successMessage = "";
  failMessage = "";
  reschedule = [];
  pageHeading = "";
  public profilePath = null;
  order_id = 0;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public api: ApiService
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    this.statusForm = this.fb.group({
      status: [""],
      order_id: [""],
      user_id: [this.myapp.loginUserkiId],
      token: [this.myapp.token],
    });
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      let not_id = parseInt(params.get("not_id"));
      if (id) {
        this.getOrderDetail(id, not_id);
        this.pageHeading = "Order Detail";
        this.order_id = id;
      }
    });
  }

  getOrderDetail(id, not_id) {
    this.orderParam = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      order_id: id,
      not_id: not_id,
    };
    this.spinner.show();
    this.api
      .callAPI(this.orderParam, "admin/orderDetail")
      .subscribe((response) => {
        this.spinner.hide();
        if (response.status === true) {
          this.spinner.hide();

          this.pick_up_detail = response.data.pick_up_detail;
          this.drop_off_detail = response.data.drop_off_detail;
          this.order_status = response.data.order_status_data;
          this.customer_data = response.data.customer_data;
          this.vendor_data = response.data.vendor_data;
          this.order_details = response.data.order_details;
          this.order_items = response.data.order_items;
          this.reschedule = response.data.reschedule;

          console.log("order item:", this.order_items);
          this.statusForm.patchValue({
            order_id: this.order_id,
            status: this.order_details["status"],
          });
          //console.log("order status:", this.order_details["status"]);
          //this.successMessage = response.message;
        } else {
          this.spinner.hide();
          this.failMessage = response.message;
          //console.log(response.message);
        }
      });
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.api.convertToDateTime(data, option);
  }

  changeOrderStatus() {
    let conf = confirm("Do you want to change order status?");
    if (conf) {
      this.myapp.hideAlerts();
      this.myapp.spinner.show();
      this.api
        .callAPI(this.statusForm.value, "admin/changeOrderStatus")
        .subscribe((response) => {
          if (response.status) {
            this.myapp.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.ngOnInit();
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }
}
