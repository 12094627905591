import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { ApiService } from "src/app/service/api.service";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { interval, Subscription } from "rxjs";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"]
})
export class NotificationComponent implements OnInit {
  pageHeading = "";
  public adminMenu = false;
  isRead = true;
  isDelete = true;
  public count: any = [];
  public vendorMenu = false;
  mySubscription: Subscription;
  notification: any = [];
  user = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    public spinner: NgxSpinnerService,
    public api: ApiService
  ) {}

  ngOnInit() {
    //in 10 seconds do something
    this.mySubscription = interval(30000).subscribe(x => {
      this.getNotifications("all");
    });

    this.pageHeading = "Notification Management";
    this.getNotifications("all");
    if (window.localStorage.getItem("role_id") == "1") {
      this.adminMenu = true;
    } else {
      this.vendorMenu = true;
    }
  }

  getNotifications(all) {
    this.user = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      noti_type: all
    };
    // this.myapp.spinner.show();
    this.api
      .callAPI(this.user, "admin/getNotifications")
      .subscribe(response => {
        if (response.status === true) {
          // this.myapp.spinner.hide();
          this.notification = response.data;
        } else {
          this.isDelete = false;
          this.isRead = false;
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      });
  }

  delete(admin_noti_id) {
    this.myapp.hideAlerts();
    this.user = {
      user_id: this.myapp.loginUserkiId,
      token: this.myapp.token,
      admin_noti_id: admin_noti_id
    };
    let conf = confirm("Do you want to perform this action?");
    if (conf) {
      this.spinner.show();
      this.api
        .callAPI(this.user, "admin/deleteNotification")
        .subscribe(response => {
          this.spinner.hide();
          if (response.status === true) {
            this.spinner.hide();
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.getNotifications("all");
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }

  markAllRead() {
    this.myapp.hideAlerts();
    let conf = confirm("Do you want to perform this action?");
    if (conf) {
      this.spinner.show();
      this.api
        .callAPI(this.myapp.loginUser, "admin/markAllNotificationAsRead")
        .subscribe(response => {
          this.spinner.hide();
          if (response.status === true) {
            this.spinner.hide();
            this.myapp.notificationCount = 0;
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.getNotifications("all");
          } else {
            this.myapp.spinner.hide();
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
    }
  }

  clearAll() {
    this.myapp.hideAlerts();
    let conf = confirm("Do you want to perform this action?");
    if (conf) {
      this.api
        .callAPI(this.myapp.loginUser, "admin/clearNotifications")
        .subscribe(response => {
          if (response.status === true) {
            // this.spinner.hide();
            // this.myapp.showSuccessAlert = true;
            // this.myapp.successMsg = response.message;
            this.isDelete = false;
            this.count = [];
            this.isRead = false;
            this.notification = [];
            this.myapp.notificationCount = 0;
          }
        });
    }
  }
}
