import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-verify-phone-otp",
  templateUrl: "./verify-phone-otp.component.html",
  styleUrls: ["./verify-phone-otp.component.css"]
})
export class VerifyPhoneOtpComponent implements OnInit {
  verifyPhoneOtp: FormGroup;
  ChangePhoneForm: FormGroup;
  successMessage = "";
  failMessage = "";
  pageHeading = "";
  user_verification_id = null;
  value = null;
  phoneotp = null;
  mobile_number = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public myapp: AppComponent,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.value = localStorage.getItem("value");
    this.phoneotp = localStorage.getItem("otp");
    this.mobile_number = localStorage.getItem("mobile_number");

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.user_verification_id = id;
      }
    });
    this.pageHeading = "Change Phone";
    //Form Model Declaration with form validation for the fields
    this.verifyPhoneOtp = this.fb.group({
      otp: [
        "",
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
          Validators.pattern("^[0-9()]+$")
        ]
      ]
    });
  }

  onOtpSubmit() {
    this.myapp.showSuccessAlert = false;
    this.myapp.showErrorAlert = false;
    this.myapp.successMsg = "";
    this.myapp.errorMsg = "";

    const formData = new FormData();
    this.myapp.spinner.show();
    if (this.value == 1) {
      formData.append("otp", this.verifyPhoneOtp.get("otp").value);
      formData.append("user_verification_id", this.user_verification_id);
      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);

      this.api.callAPI(formData, "vendor/verifyMyOtp1").subscribe(response => {
        if (response.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.router.navigate(["../vendor/change-phone"]);
          console.log(response.data);
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          console.log(response.message);
        }
      });
    }
    if (this.value == 2) {
      formData.append("otp", this.verifyPhoneOtp.get("otp").value);
      formData.append("mobile_number", this.mobile_number);
      formData.append("user_verification_id", this.user_verification_id);
      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);

      this.api.callAPI(formData, "vendor/verifyMyOtp2").subscribe(response => {
        if (response.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.router.navigate(["../vendor/settings"]);
          console.log(response.message);
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          console.log(response.message);
        }
      });
    }
  }

  //Getor - To access the fields directly in the form in HTML
  get otp() {
    return this.verifyPhoneOtp.get("otp");
  }
}
