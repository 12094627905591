import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-phone",
  templateUrl: "./phone.component.html",
  styleUrls: ["./phone.component.css"],
})
export class PhoneComponent implements OnInit {
  ChangePhoneForm: FormGroup;
  successMessage = "";
  failMessage = "";
  pageHeading = "";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public myapp: AppComponent,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.pageHeading = "Change Phone";
    //Form Model Declaration with form validation for the fields
    this.ChangePhoneForm = this.fb.group({
      mobile_number: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(10),
          Validators.pattern("^[0-9()]+$"),
        ],
      ],
    });
  }

  //form submit function
  onFormSubmit() {
    this.myapp.showSuccessAlert = false;
    this.myapp.showErrorAlert = false;
    this.myapp.successMsg = "";
    this.myapp.errorMsg = "";

    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append(
      "mobile_number",
      this.ChangePhoneForm.get("mobile_number").value
    );
    formData.append("vendor_id", this.myapp.vendorUserId);
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);

    this.api
      .callAPI(formData, "vendor/changeMyPhone2")
      .subscribe((response) => {
        if (response.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          localStorage.removeItem("value");
          localStorage.removeItem("otp");
          localStorage.removeItem("mobile_number");
          window.localStorage.setItem("value", "2");
          window.localStorage.setItem("otp", response.data.otp);
          window.localStorage.setItem(
            "mobile_number",
            response.data.mobile_number
          );
          this.router.navigate([
            "../vendor/verify-phone-otp/" + response.data.user_verification_id,
          ]);
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          console.log(response.message);
        }
      });
  }

  //Getor - To access the fields directly in the form in HTML
  get mobile_number() {
    return this.ChangePhoneForm.get("mobile_number");
  }
}
