import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { catchError, tap, map, retry } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  /*===API URLs===*/
  /*public baseUrl = "http://127.0.0.1/fnf-api/api/"; //My System IP Address
  public mediaUrl = "http://127.0.0.1/fnf-api/uploads/"; //My System IP Address
  public siteUrl = "http://localhost:4200/";*/

  /*====Development Server URLs====*/
  /*public baseUrl = "http://fnf-api.ctportfolio.in/api/";
  public mediaUrl = "http://fnf-api.ctportfolio.in/uploads/";
  public siteUrl = "http://fnf-api.ctportfolio.in/";*/

  /*====Live Server URLs====*/
  // public baseUrl = "https://fluffnfold.co.nz/fnf-api/api/";
  // public mediaUrl = "https://fluffnfold.co.nz/fnf-api/uploads/";
  // public siteUrl = "https://fluffnfold.co.nz/fnf-panel/";

  public baseUrl = "https://laundry.ctportfolio.in/public/api/";
  public mediaUrl = "https://laundry.ctportfolio.in/public/uploads/";
  public siteUrl = "https://laundry-panel.ctportfolio.in/";

  constructor(private router: Router, private http: HttpClient) {}

  //API calling function
  callAPI(data, apiFunction) {
    let url: string = this.baseUrl + apiFunction;
    return this.http.post<any>(url, data).pipe(catchError(this.errorHandler));
  }

  //function to check User is logged in or NOT
  isUserLogin() {
    if (!window.localStorage.getItem("isUserLogin")) {
      this.router.navigate(["/login"]);
      return;
    } else return true;
  }

  // function to Validate Admin Routes
  isAdminRoute() {
    if (window.localStorage.getItem("role_id") != "1") {
      this.router.navigate(["/admin-dashboard"]);
      return;
    }
  }

  //User Profile Pic Path
  userProfilePic() {
    return this.mediaUrl + "customer/";
  }

  //Service Pic Path
  serviceImgPath() {
    return this.mediaUrl + "services/";
  }

  //Banner Pic Path
  bannerImgPath() {
    return this.mediaUrl + "banners/";
  }

  //Package Pic Path
  packageImgPath() {
    return this.mediaUrl + "packages/";
  }

  //Error Handler Method calling from all the services
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }

  //Convert time from 24 hr to AM-PM
  convertTime(timeString) {
    let H = +timeString.substr(0, 2);
    let h = H % 12 || 12;
    let ampm = H < 12 || H === 24 ? " AM" : " PM";
    return (timeString = h + timeString.substr(2, 3) + ampm);
  }

  //Function to convert API Date in DateTime Formate
  convertToDateTime(data, option) {
    if (data == "" || data == undefined || data == "") {
      return "";
    }
    let dateArray = data.split(" ");
    let dt = dateArray[0].split("-");
    let onlyDate = dt[2] + "-" + dt[1] + "-" + dt[0];
    if (option == "date") {
      return onlyDate;
    } else {
      return onlyDate + ", " + this.convertTime(dateArray[1]);
    }
  }

  stringToDateFun(str) {
    if (str == "" || str == undefined || str == "") {
      return "";
    }
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  timeWithoutSeconds(str) {
    if (str != null && str != "") {
      let timeArray = str.split(":");
      return (timeArray[0] + ":" + timeArray[1]).toString();
    } else return "";
  }

  timevalidation(str) {
    //var startValue = new Date();
    console.log("str" + str);
    let startValue = str.split(":");
    return (startValue[0] + ":" + startValue[1]).toString();
  }

  todayDate() {
    let date = new Date(),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
}
