import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-view-orders",
  templateUrl: "./view-orders.component.html",
  styleUrls: ["./view-orders.component.css"]
})
export class ViewOrdersComponent implements OnInit {
  public orders: any = [];
  vendors: any = [];
  successMessage = "";
  failMessage = "";
  pageHeading = "";
  public ordersCount: any = [];
  public profilePath = null;
  page = 1; //used for load more
  hideLoadMore = true;
  changeVendorForm: FormGroup;
  filterForm: FormGroup;
  order = {};
  showModal = true;
  newVendorId = 0;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public myapp: AppComponent,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.getVendors();
    this.getAllOrders("", "", "");
    this.pageHeading = "Order Management";

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      vendor: [""],
      order_status: [""]
    });

    //Form Model Declaration with form validation for the fields
    this.changeVendorForm = this.fb.group({
      vendor_id: ["", [Validators.required]],
      order_id: [""]
    });
  }

  getAllOrders(keyword, vendor, order_status) {
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", keyword);
    formData.append("vendor", vendor);
    formData.append("order_status", order_status);

    this.api.callAPI(formData, "admin/getOrders").subscribe(response => {
      if (response.status === true) {
        this.ordersCount = response.data;
        this.orders = response.data.order_data;
        //console.log(this.orders);
        //console.log(this.ordersCount);
      } else {
        this.ordersCount = [];
        this.orders = [];
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  // Function to get all drivers
  getVendors() {
    this.api
      .callAPI(this.myapp.loginUser, "admin/getAllVendors")
      .subscribe(response => {
        if (response.status === true) {
          this.vendors = response.data;
        }
      });
  }

  //form submit function
  onFormSubmit() {
    if (this.changeVendorForm.get("vendor_id").value != this.newVendorId) {
      this.myapp.spinner.show();
      const formData = new FormData();
      formData.append(
        "vendor_id",
        this.changeVendorForm.get("vendor_id").value
      );
      formData.append("order_id", this.changeVendorForm.get("order_id").value);
      formData.append("user_id", this.myapp.loginUserkiId);
      formData.append("token", this.myapp.token);

      //console.log("Service Image:" + this.service_image.value);
      this.api.callAPI(formData, "admin/changeVendor").subscribe(response => {
        if (response.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.ngOnInit();
          /*setTimeout(() => {
            window.location.reload();
          }, 1000);*/

          // Hide the Modal
          /*this.showModal = false;
          let closeModal: HTMLElement = document.getElementsByClassName(
            "body"
          )[0] as HTMLElement;
          closeModal.classList.remove("modal-open");
          let closeModalpadding: HTMLElement = document.getElementsByClassName(
            "body"
          )[0] as HTMLElement;
          closeModalpadding.style.padding = "0px";
          let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
            "modal-backdrop"
          )[0] as HTMLElement;
          ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);*/
        } else {
          this.myapp.spinner.hide();
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });
    } else {
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "New vendor should be different";
    }
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.changeVendorForm.reset();
    //Patch the required data in Form Modal
    this.changeVendorForm.patchValue({
      vendor_id: "",
      order_id: ""
    });
  }

  //Getor - To access the fields directly in the form in HTML
  get order_id() {
    return this.changeVendorForm.get("order_id");
  }
  get vendor_id() {
    return this.changeVendorForm.get("vendor_id");
  }

  changeVendor(order_id, vendor_id) {
    this.newVendorId = vendor_id;
    this.changeVendorForm.patchValue({
      order_id: order_id
    });
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("user_id", this.myapp.loginUserkiId);
    formData.append("token", this.myapp.token);
    formData.append("keyword", this.filterForm.get("keyword").value);
    formData.append("vendor", this.filterForm.get("vendor").value);
    formData.append("order_status", this.filterForm.get("order_status").value);
    formData.append("page", pageNo);

    this.api.callAPI(formData, "admin/getOrders").subscribe((response: any) => {
      if (response.status == true) {
        this.myapp.spinner.hide();
        this.page++; //Increment of the page
        this.myapp.isLoadingResults = false;
        for (let i = 0; i < response.data.order_data.length; i++) {
          this.orders.push(response.data.order_data[i]);
        }
        //Hide the load more button if reaches ot limit
        if (response.limit != response.data.order_data.length)
          this.hideLoadMore = false;
      } else {
        this.myapp.spinner.hide();
        this.myapp.isLoadingResults = false;
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
      }
    });
  }

  //On Keyword filter
  filterData() {
    this.hideLoadMore = true;
    this.page = 1;

    let keyword = this.filterForm.get("keyword").value;
    let vendor = this.filterForm.get("vendor").value;
    let order_status = this.filterForm.get("order_status").value;

    this.getAllOrders(keyword, vendor, order_status);
  }
}
